import { z } from "zod"

export const LayerType = {
	landerz: "landerz",
	off_market: "off_market",
	fbso: "fsbo",
	external: "external",
	co_listing: "co_listing",
	transaction: "transaction",
} as const

export const LayerTypes = Object.values(LayerType)

export const DevelopmentType = {
	residential: "Résidentiel",
	mixed: "Mixte",
	commercial: "Commercial",
	subdivision: "Lotissement",
	unidentified: "Inconnu",
	agricultural: "Agricole",
	industrial: "Industriel",
	institutional: "Institutionnel",
	hotelier: "Hotelier",
} as const

export enum DevelopmentPotential {
	High = "High",
	Medium = "Medium",
	Low = "Low",
	None = "None",
	Unspecified = "Unspecified",
}

export enum Borough {
	Verdun = "Verdun",
	LaBaie = "La Baie",
	LeVieuxLongueuil = "Le Vieux-Longueuil",
	Desjardins = "Desjardins",
	LaHauteSaintCharles = "La Haute-Saint-Charles",
	Grenville = "Grenville",
	CoteDesNeigesNotreDameDeGrace = "Côte-des-Neiges - Notre-Dame-de-Grâce",
	MontrealNord = "Montréal-Nord",
	AhuntsicCartierville = "Ahuntsic-Cartierville",
	Jonquiere = "Jonquière",
	Lachine = "Lachine",
	LesChutesDeLaChaudiereEst = "Les Chutes-de-la-Chaudière-Est",
	PierrefondsRoxboro = "Pierrefonds - Roxboro",
	Calumet = "Calumet",
	Outremont = "Outremont",
	LesRivieres = "Les Rivières",
	SaintLaurent = "Saint-Laurent",
	MacNider = "MacNider",
	Chicoutimi = "Chicoutimi",
	Arrondissement4 = "Arrondissement 4",
	Arrondissement2 = "Arrondissement 2",
	Beauport = "Beauport",
	LeSudOuest = "Le Sud-Ouest",
	SainteFoySilleryCapRouge = "Sainte-Foy - Sillery - Cap-Rouge",
	SaintHubert = "Saint-Hubert",
	Arrondissement1 = "Arrondissement 1",
	RivieredesPrairiesPointeAuxTrembles = "Rivière-des-Prairies - Pointe-aux-Trembles",
	LIleBizardSainteGenevieve = "LÎle-Bizard - Sainte-Geneviève",
	LePlateauMontRoyal = "Le Plateau-Mont-Royal",
	VilleMarie = "Ville-Marie",
	MercierHochelagaMaisonneuve = "Mercier - Hochelaga-Maisonneuve",
	RosemontLaPetitePatrie = "Rosemont - La Petite-Patrie",
	Arrondissement3 = "Arrondissement 3",
	Charlesbourg = "Charlesbourg",
	GreenfieldPark = "Greenfield Park",
	LaSalle = "LaSalle",
	LaCiteLimoilou = "La Cité-Limoilou",
	VilleraySaintMichelParcExtension = "Villeray - Saint-Michel - Parc-Extension",
	SaintLeonard = "Saint-Léonard",
	LesChutesDeLaChaudiereOuest = "Les Chutes-de-la-Chaudière-Ouest",
	Anjou = "Anjou",
}

export const DevelopmentTypes = Object.values(DevelopmentType)

export const SalesProcessStatus = {
	open: "open",
	accepted_psa: "accepted_psa",
}
/** The Zod schema used to validate a listing */
export const ListingSchema = z.object({
	/// the listing id
	id: z.string(),
	hubspot_record_id: z.string(),
	type: z.nativeEnum(LayerType),
	// typeDev: z.string(),
	typeDevArray: z.array(z.nativeEnum(DevelopmentType)),
	constructible: z.nullable(z.number()),
	superficy: z.number(),
	longitude: z.number(),
	latitude: z.number(),
	price: z.nullable(z.number()),
	pricePerSqft: z.nullable(z.number()),
	pricePerConstructibleSqft: z.nullable(z.number()),
	link: z.nullable(z.string()),
	address: z.string(),
	city: z.string(),
	state: z.string(),
	postal_code: z.string(),
	image: z.nullable(z.string()),
	off_market_radius: z.number(),
	sales_process_status: z.nativeEnum(SalesProcessStatus),
	launch_date: z.date().optional(),
	launch_date_ms: z.number().optional(),
	call_for_bids: z.boolean(),
	broker_email: z.string(),
	broker_first_name: z.string(),
	broker_last_name: z.string(),
	broker_phone_number: z.string(),
	broker_picture_url: z.string(),
})

/** A listing is the primary thing our app displays. It represents lots
 * suitable for redevelopment on the market (or likely to be, ex: off_market),
 * either through Landerz brokerage activities or another broker */
export interface Listing extends z.infer<typeof ListingSchema> {}

/** The Zod schema used to validate a parcel (GraphQL version) */
export const ParcelSchema = z.object({
	parcelID: z.string(),
	location: z.object({
		coordinates: z.object({
			longitude: z.string(),
			latitude: z.string(),
		}),
	}),
})

export interface Parcel extends z.infer<typeof ParcelSchema> {}

/** The Zod schema used to validate a prospect (GraphQL version) */
export const ProspectSchema = z.object({
	prospectID: z.string(),
	potential: z.nativeEnum(DevelopmentPotential),
})

export interface Prospect extends z.infer<typeof ProspectSchema> {}

export const TransactionType = ["Land", "Not Land", "Both", "Unsure", "Undetermined"]
export const TransactionTypeDefault = "Undetermined"
export const TransactionSaleContext = ["Normal", "Linked", "Assembly"]
export const TransactionPartyType = ["Individual", "Company"]
export const TransactionLandSubtype = [
	"Development - Gross Land",
	"Development - Serviced Land",
	"Conservation",
	"Agricultural",
	"Forestry",
	"Domain",
	"Special Assets",
	"Other",
]
export const TransactionAuthorizedUse = [
	"Single-Family",
	"Résidential - Low rise",
	"Résidential - High rise",
	"Commercial",
	"Industrial",
	"Park/Conservation",
]
export const TransactionCurrentState = [
	"Vacant Land",
	"Building to demolish",
	"Building in usable shape",
	"Transformation",
]
export const TransactionCodificationType = ["Estimate", "Project under approval", "Approved/Built/under construction"]
export const TransactionProjectApproval = ["Plein droit", "Modification réglementaire"]
export const TransactionProjectApprovalAtDeedOfSale = ["Completed", "In progress", "Incomplete", "Unknown"]
export const TransactionProjectType = [
	"Single-Family",
	"Residential - Low rise",
	"Residential - High rise",
	"Commercial",
	"Industrial",
]
export const TransactionUnitType = ["Single-Family", "Multi-Family"]
export const TransactionCPTAQ = ["Inside", "Outside"]
export const TransactionUrbanPerimeter = ["Inside", "Outside"]
export const TransactionInfrastructure = ["Serviced", "Unserviced"]
export const TransactionLandShape = ["Regular", "Irregular"]
export const TransactionContamination = ["Yes", "No", "Probable", "Improbable", "Decontaminated"]

export enum TransactionMetadataStatus {
	Pending = "pending",
	InProgress = "in progress",
	Deferred = "deferred",
	Done = "done",
}

export enum TransactionCategory {
	Industrial = "Industriel",
	Recreational = "Récréatif et forêt",
	Agricultural = "Agricole",
	Land = "Terrain",
	Residential = "Résidentiel",
	Commercial = "Commercial",
}

// prettier-ignore
export const ContactSchema = z.object({
    id:        z.string().nullish(),
    firstName: z.string().nullish(),
    lastName:  z.string().nullish(),
    address:   z.string().nullish(),
    email:     z.string().nullish(),

    // Actor
    primaryCompany: z.object({
        id:              z.string().nullish(),
        name:            z.string().nullish(),
        address:         z.string().nullish(),
        isMoral:         z.boolean().nullish(),
        neq:             z.string().nullish(),
        type:            z.string().nullish(),
        activity:        z.string().nullish(),
        representatives: z.array(z.string().nullish()).nullish(),
    }).nullish(),
})

// prettier-ignore
export const ContactListSchema = z.array(ContactSchema)

export interface Contact extends z.infer<typeof ContactSchema> {}
export interface ContactList extends z.infer<typeof ContactListSchema> {}

/** The Zod schema used to validate and transform a transaction */
// prettier-ignore
export const TransactionSchema = z
	.object({
		presentationID: z.string().nullish(),
		parcels: z
			.array(
				z
					.object({
						parcelID: z.string().nullish(),
						isActive: z.boolean().nullish(),
						location: z
							.object({
								coordinates: z
									.object({
										longitude: z.string().nullish(),
										latitude: z.string().nullish(),
									})
									.nullish(),
								address: z.string().nullish(),
							})
							.nullish(),
						superficy: z
							.object({
								squareMeters: z.number().nullish(),
								squareFeet: z.number().nullish(),
							})
							.nullish(),
					})
					.nullish()
			)
			.nullish(),

		contacts: ContactListSchema.nullish(),
		deedOfSaleDate: z.coerce.date().nullish(),
		location: z.object({
			coordinates: z.object({
				longitude: z.string().nullish(),
				latitude: z.string().nullish(),
			}),
			address: z.string().nullish(),
			municipality: z.string().nullish(),
			borough: z.string().nullish(),
		}),
		superficy: z
			.object({
				squareMeters: z.number().nullish(),
				squareFeet: z.number().nullish(),
			})
			.nullish(),
		seller: z
			.object({
				id:              z.number().nullish(),
				name:            z.string().nullish(),
				adresse:         z.string().nullish(),
				isMoral:         z.boolean().nullish(),
				neq:             z.string().nullish(),
                representatives: z.array(z.string().nullish()).nullish(),
                type:            z.string().nullish(),
                activity:        z.string().nullish(),
			})
			.nullish(),
		buyer: z
			.object({
				id:              z.number().nullish(),
				name:            z.string().nullish(),
				adresse:         z.string().nullish(),
				isMoral:         z.boolean().nullish(),
				neq:             z.string().nullish(),
                representatives: z.array(z.string().nullish()).nullish(),
                type:            z.string().nullish(),
                activity:        z.string().nullish(),
			})
			.nullish(),

		id:                              z.number().nullish(),
		inscriptionNumber:               z.string().nullish(),
		date:                            z.date().nullish(),
		psaDate:                         z.coerce.date().nullish(),
		type:                            z.string().nullish(),
		salePrice:                       z.number().nullish(),
		dataRoomLink:                    z.string().nullish(),
		comments:                        z.string().nullish(),
		address:                         z.string().nullish(),
		city:                            z.string().nullish(),
		postalCode:                      z.string().nullish(),
		saleContext:                     z.string().nullish(),
		broker:                          z.string().nullish(),
		agency:                          z.string().nullish(),
		marketplace:                     z.string().nullish(),
		marketplaceId:                   z.string().nullish(),
		mortgage:                        z.boolean().nullish(),
		lender:                          z.string().nullish(),
		lenderAmount:                    z.number().nullish(),
		pricePerLandSqft:                z.number().nullish(),
		pricePerDevelopableSqft:         z.number().nullish(),
		pricePerBuildableSqft:           z.number().nullish(),
		pricePerUnit:                    z.number().nullish(),
		landSubtype:                     z.string().nullish(),
		authorizedUses:                  z.array(z.string().nullish()).nullish(),
		currentState:                    z.string().nullish(),
		tod:                             z.boolean().nullish(),
		zoningPlanLink:                  z.string().nullish(),
		zoningGridLink:                  z.string().nullish(),
		urbanPlanLink:                   z.string().nullish(),
		padLink:                         z.string().nullish(),
		otherZoningDocumentLink:         z.string().nullish(),
		codificationType:                z.string().nullish(),
		projectApproval:                 z.string().nullish(),
		projectApprovalAtDeedOfSale:     z.string().nullish(),
		projectDocument:                 z.string().nullish(),
		projectLink:                     z.string().nullish(),
		project3dModelLink:              z.string().nullish(),
		projectTypes:                    z.array(z.string().nullish()).nullish(),
		stories:                         z.number().nullish(),
		siteCoverageRatio:               z.number().nullish(),
		density:                         z.number().nullish(),
		buildableAreaSqft:               z.number().nullish(),
		numberOfUnits:                   z.number().nullish(),
        parkingOutdoorCount:             z.number().nullish(),
        parkingIndoorCount:              z.number().nullish(),
        parkingTotal:                    z.number().nullish(),
        parkingRatio:                    z.number().nullish(),
		projectComments:                 z.string().nullish(),
		unitType:                        z.string().nullish(),
		unitAreaSqft:                    z.number().nullish(),
		cptaq:                           z.array(z.string().nullish()).nullish(),
		urbanPerimeter:                  z.array(z.string().nullish()).nullish(),
		infrastructure:                  z.array(z.string().nullish()).nullish(),
		landShape:                       z.string().nullish(),
		parcelReport:                    z.string().nullish(),
		contamination:                   z.string().nullish(),
		contaminationCost:               z.number().nullish(),
		wetlands:                        z.boolean().nullish(),
		floodableZone:                   z.boolean().nullish(),
		endangeredSpecies:               z.boolean().nullish(),
		deductionRatio:                  z.number().nullish(),
		deductionComments:               z.string().nullish(),
		deductionFiles:                  z.array(z.string().nullish()).nullish(),
		developableLandAreaSqft:         z.number().nullish(),
		infrastructureDeductionRatio:    z.number().nullish(),
		infrastructureDeductionComments: z.string().nullish(),
		netLandAreaSqft:                 z.number().nullish(),
		category:                        z.string().nullish(),
        hasLegacy:                       z.boolean().nullish(),
        interactiveMapUrl:               z.string().nullish(),
        planMapUrl:                      z.string().nullish(),
        sateliteMapUrl:                  z.string().nullish(),
        zoningImageUrl:                  z.string().nullish(),
        volumetricImageUrl:              z.string().nullish(),
        cityInformationSheetUrl:         z.string().nullish(),
        deedOfSaleUrl:                   z.string().nullish(),
        zoningReportUrl:                 z.string().nullish(),
		metadataStatus:                  z.string().nullish(),

	})
	.transform(raw => {
		return {
			...raw,
			presentationId: raw.presentationID,
			id: raw.inscriptionNumber,
			location: {
				...raw.location,
				parcelNumbers: raw.parcels.map(parcel => parcel?.parcelID),
				area: raw.superficy?.squareFeet,
			},
			deal: {
				salePrice: raw.salePrice,
				registryDate: raw.deedOfSaleDate ? new Date(raw.deedOfSaleDate) : undefined,
				seller: {
					name: raw.seller?.name,
					neq: raw.seller?.neq,
				},
				buyer: {
					name: raw.buyer?.name,
					neq: raw.buyer?.neq,
				},
			},
            metadata: {
                status: raw.metadataStatus,
            },
		}
	})

export interface Transaction extends z.infer<typeof TransactionSchema> {}

/** The Zod schema used to validate a municipality */
export const MunicipalitySchema = z.object({
	name: z.string(),
})

export interface Municipality extends z.infer<typeof MunicipalitySchema> {}

/** The Zod schema used to validate transaction overrides */
export const TransactionOverridesSchema = z.object({})

export interface TransactionOverrides extends z.infer<typeof TransactionOverridesSchema> {}
