<template>
	<div class="transaction">
		<div class="header">
			<input
				type="checkbox"
				@change.stop="event => handleAddToExportList(event, transaction)"
				@click.stop
				:checked="store.transactionExportList.has(transaction.presentationId)"
			/>
			<div class="title">
				<h3 class="address" :class="transaction.location.address ?? 'unavailable'">
					{{ transaction.location.address || $t("unknownAddress") }}
				</h3>
				<div class="borough">
					{{ transaction.location?.borough }}
				</div>
			</div>
		</div>
		<div class="separator"></div>

		<div class="items">
			<header>
				<h1 class="price">
					<span>
						<span class="currency">$</span>
						<span class="amount">{{ (transaction.deal?.salePrice || "").toLocaleString() }}</span>
					</span>
				</h1>

				<marquee class="legacy-hint">
					{{ transaction.hasLegacy ? "legacy" : "" }}
				</marquee>

				<label v-if="isWriter" class="status">
					<select name="metadata_status" @change="onStatusChange">
						<option
							v-for="(status, idx) of TransactionMetadataStatus"
							:value="status"
							:key="idx"
							:selected="status == transaction.metadata.status"
						>
							{{ status }}
						</option>
					</select>
				</label>
			</header>

			<!-- prettier-ignore -->
			<div class="details">

				<fieldset>
					<legend>info</legend>

					<TransactionCardDetailedEditableField
						type      = "text"
						title     = "Inscription Number"
						name      = "inscriptionNumber"
						:value    = "spaceOutNumbers(transaction.inscriptionNumber)"
						@input    = "onFieldInput"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "url"
						title     = "Deed Of Sale"
						:name     = "getUrlTrailingSegments(transaction.deedOfSaleUrl, 2) || 'N/A'"
						:value    = "transaction.deedOfSaleUrl"
					/>
					<TransactionCardDetailedEditableField
						type      = "date"
						title     = "Deed Of Sale Date"
						name      = "deedOfSaleDate"
						:value    = "transaction.deedOfSaleDate.toISOString().split('T')[0]"
						@input    = "onFieldInput"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "date"
						title     = "PSA Date"
						name      = "psaDate"
						:value    = "transaction.psaDate && transaction.psaDate.toISOString().split('T')[0]"
						@input    = "onFieldInput"
					/>
					<TransactionCardDetailedEditableField
						type      = "select"
						title     = "Type"
						name      = "type"
						:options  = "TransactionType"
						:value    = "transaction.type || TransactionTypeDefault"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						v-if      = "isWriter"
						info
						type      = "text"
						title     = "CMSD Category"
						name      = "cmsdCategory"
						:value    = "transaction.category"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "textarea"
						title     = "Parcels"
						name      = "parcels"
						:value    = "spaceOutNumbers(...transaction.location?.parcelNumbers).join(', ')"
						@input    = "onFieldInput"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "url"
						title     = "Parcels Image"
						:name     = "getUrlTrailingSegments(transaction.interactiveMapUrl, 2) || 'N/A'"
						:value    = "transaction.interactiveMapUrl"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "url"
						title     = "3D Image"
						:name     = "getUrlTrailingSegments(transaction.sateliteMapUrl, 2) || 'N/A'"
						:value    = "transaction.sateliteMapUrl"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						title     = "Total Land Area"
						symbol    = "⏍"
						name      = "superficy"
						:value    = "roundTo(transaction.superficy?.squareFeet ?? null, 1)"
						@input    = "onFieldInput"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						:rounding = "0.01"
						title     = "Sale Price"
						symbol    = "$"
						name      = "salePrice"
						:value    = "roundTo(transaction.salePrice, 0.01)"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "url"
						title     = "Assessment Role"
						:name     = "getUrlTrailingSegments(transaction.cityInformationSheetUrl, 2) || 'N/A'"
						:value    = "transaction.cityInformationSheetUrl"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						title     = "Data Room Link"
						name      = "dataRoomLink"
						:value    = "transaction.dataRoomLink"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "textarea"
						title     = "Comments"
						name      = "comments"
						:value    = "transaction.comments"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
				</fieldset>

				<fieldset>
					<legend>pricing</legend>

					<!-- ft² -->
					<TransactionCardDetailedEditableField
						type      = "number"
						:rounding = "0.01"
						title     = "Land"
						symbol   = "$ / ⏍"
						name      = "pricePerLandSqft"
						:value    = "roundTo(transaction.pricePerLandSqft, 0.01)"
						@input    = "onFieldInput"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						:rounding = "0.01"
						title     = "Developable"
						symbol   = "$ / ⏍"
						name      = "pricePerDevelopableSqft"
						:value    = "roundTo(transaction.pricePerDevelopableSqft, 0.01)"
						@input    = "onFieldInput"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						:rounding = "0.01"
						title     = "Buildable"
						symbol   = "$ / ⏍"
						name      = "pricePerBuildableSqft"
						:value    = "roundTo(transaction.pricePerBuildableSqft, 0.01)"
						@input    = "onFieldInput"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						:rounding = "100"
						title     = "Unit"
						symbol    = '$'
						name      = "pricePerUnit"
						:value    = "roundTo(transaction.pricePerUnit, 100)"
						@input    = "onFieldInput"
						disabled
					/>
				</fieldset>

				<fieldset>
					<legend>location</legend>

					<TransactionCardDetailedEditableField
						type      = "text"
						title     = "Address"
						name      = "address"
						:value    = "transaction.location?.address"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						title     = "Municipality"
						name      = "city"
						:value    = "transaction.location?.municipality"
						@input    = "onFieldInput"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						title     = "Postal Code"
						name      = "postalCode"
						:value    = "spaceOutNumbers(transaction.postalCode).join(', ')"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
				</fieldset>

				<fieldset>
					<legend>parties</legend>

					<TransactionCardDetailedEditableField
						type      = "select"
						title     = "Sale Context"
						name      = "saleContext"
						:options  = "TransactionSaleContext"
						:value    = "transaction.saleContext"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>

					<h2>buyer</h2>

					<TransactionCardDetailedEditableField
						type      = "text"
						title     = "Name"
						name      = "buyer"
						:value    = "transaction.buyer?.name"
						@input    = "onFieldInput"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						title     = "Representatives"
						name      = "buyerRepresentatives"
						:value    = "transaction.buyer?.representatives"
						@input    = "onFieldInput"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "select"
						title     = "Type"
						name      = "buyerType"
						:options  = "TransactionPartyType"
						:value    = "transaction.buyer?.type"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						title     = "NEQ"
						name      = "buyerNeq"
						:value    = "transaction.buyer?.neq"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						title     = "Activity"
						name      = "buyerActivity"
						:value    = "transaction.buyer?.activity"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>

					<button class="contact-link" @click="contactModalIsOpen = true">
						Add Buyer Contacts
					</button>

					<div class="contacts">
						<div v-for="contact in transaction.contacts" :key="contact.id" class="contact" :title="contact.address || $t('unknownAddress')">
							<div class="info">
								<span class="name">
									{{ contact.firstName }} {{ contact.lastName }}
								</span>
								<span class="company">
									{{ contact.primaryCompany?.name }}
								</span>
								<span class="email">
									{{ contact.email }}
								</span>
							</div>
							<div class="remove" @click="event => handleRemoveContact(transaction, contact)">✖</div>
						</div>
					</div>

					<h2>seller</h2>

					<TransactionCardDetailedEditableField
						type      = "text"
						title     = "Name"
						name      = "seller"
						:value    = "transaction.seller?.name"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						title     = "Representatives"
						name      = "sellerRepresentatives"
						:value    = "transaction.seller?.representatives"
						@input    = "onFieldInput"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "select"
						title     = "Type"
						name      = "sellerType"
						:options  = "TransactionPartyType"
						:value    = "transaction.seller?.type"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						title     = "NEQ"
						name      = "sellerNeq"
						:value    = "transaction.seller?.neq"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						title     = "Activity"
						name      = "sellerActivity"
						:value    = "transaction.seller?.activity"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>

					<h2>Broker</h2>

					<TransactionCardDetailedEditableField
						type      = "text"
						title     = "Broker"
						name      = "broker"
						:value    = "transaction.broker"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						title     = "Agency"
						name      = "agency"
						:value    = "transaction.agency"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						title     = "Marketplace"
						name      = "marketplace"
						:value    = "transaction.marketplace"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						title     = "Marketplace ID"
						name      = "marketplaceId"
						:value    = "transaction.marketplaceId"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
				</fieldset>

				<fieldset>
					<legend>lender</legend>

					<TransactionCardDetailedEditableField
						type      = "checkbox"
						title     = "Mortgage"
						name      = "mortgage"
						:value    = "transaction.mortgage"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						title     = "Lender Name"
						name      = "lender"
						:value    = "transaction.lender"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						:rounding = "0.01"
						title     = "amount"
						symbol    = "$"
						name      = "lenderAmount"
						:value    = "roundTo(transaction.lenderAmount, 0.01)"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
				</fieldset>

				<fieldset>
					<legend>type</legend>

					<TransactionCardDetailedEditableField
						type      = "select"
						title     = "Land"
						name      = "landSubtype"
						:options  = "TransactionLandSubtype"
						:value    = "transaction.landSubtype"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "select"
						multiple
						title     = "Authorized Uses"
						name      = "authorizedUses"
						:options  = "TransactionAuthorizedUse"
						:value    = "transaction.authorizedUses"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "select"
						title     = "Current State"
						name      = "currentState"
						:options  = "TransactionCurrentState"
						:value    = "transaction.currentState"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "checkbox"
						title     = "TOD"
						name      = "tod"
						:value    = "transaction.tod"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
				</fieldset>

				<fieldset>
					<legend>zoning</legend>

					<TransactionCardDetailedEditableField
						type      = "text"
						title     = "Plan Link"
						name      = "zoningPlanLink"
						:value    = "transaction.zoningPlanLink"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "url"
						title     = "Plan Image"
						:name     = "getUrlTrailingSegments(transaction.zoningImageUrl, 2) || 'N/A'"
						:value    = "transaction.zoningImageUrl"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "url"
						title     = "Grid File"
						:name     = "getUrlTrailingSegments(transaction.zoningReportUrl, 2) || 'N/A'"
						:value    = "transaction.zoningReportUrl"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						title     = "Grid Link"
						name      = "zoningGridLink"
						:value    = "transaction.zoningGridLink"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						title     = "Urban Plan Link"
						name      = "urbanPlanLink"
						:value    = "transaction.urbanPlanLink"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						title     = "PAD Link"
						name      = "padLink"
						:value    = "transaction.padLink"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						title     = "Other Zoning Document Link"
						name      = "otherZoningDocumentLink"
						:value    = "transaction.otherZoningDocumentLink"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
				</fieldset>

				<fieldset>
					<legend>development</legend>

					<TransactionCardDetailedEditableField
						type      = "select"
						title     = "Codification Type"
						name      = "codificationType"
						:options  = "TransactionCodificationType"
						:value    = "transaction.codificationType"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>

					<h2>project</h2>

					<TransactionCardDetailedEditableField
						type      = "select"
						title     = "Current Approval"
						name      = "projectApproval"
						:options  = "TransactionProjectApproval"
						:value    = "transaction.projectApproval"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "select"
						title     = "Approval At Deed Of Sale"
						name      = "projectApprovalAtDeedOfSale"
						:options  = "TransactionProjectApprovalAtDeedOfSale"
						:value    = "transaction.projectApprovalAtDeedOfSale"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						title     = "Document"
						name      = "projectDocument"
						:value    = "transaction.projectDocument"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						title     = "Link"
						name      = "projectLink"
						:value    = "transaction.projectLink"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "url"
						title     = "CMSD 3D Model"
						:name     = "getUrlTrailingSegments(transaction.volumetricImageUrl, 2) || 'N/A'"
						:value    = "transaction.volumetricImageUrl"
					/>
					<TransactionCardDetailedEditableField
						type      = "select"
						multiple
						title     = "Types"
						name      = "projectTypes"
						:options  = "TransactionProjectType"
						:value    = "transaction.projectTypes"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						title     = "Stories"
						name      = "stories"
						:value    = "transaction.stories"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						:rounding = "0.1"
						title     = "Site Coverage Ratio"
						symbol    = "%"
						name      = "siteCoverageRatio"
						:value    = "roundTo(transaction.siteCoverageRatio, 0.1)"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						:rounding = "0.1"
						title     = "Density"
						name      = "density"
						:value    = "roundTo(transaction.density, 0.1)"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						title     = "Buildable Area"
						symbol    = "⏍"
						name      = "buildableAreaSqft"
						:value    = "roundTo(transaction.buildableAreaSqft, 1)"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "textarea"
						title     = "Comments"
						name      = "projectComments"
						:value    = "transaction.projectComments"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>

					<h2>parking</h2>

					<TransactionCardDetailedEditableField
						type      = "number"
						title     = "Outdoor"
						name      = "parkingOutdoorCount"
						:value    = "transaction.parkingOutdoorCount"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						title     = "Indoor"
						name      = "parkingIndoorCount"
						:value    = "transaction.parkingIndoorCount"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						title     = "Total"
						name      = "parkingTotal"
						:value    = "transaction.parkingTotal"
						@input    = "onFieldInput"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						:rounding = "0.1"
						title     = "Ratio"
						name      = "parkingRatio"
						:value    = "roundTo(transaction.parkingRatio, 0.1)"
						@input    = "onFieldInput"
						disabled
					/>

					<h2>units</h2>

					<TransactionCardDetailedEditableField
						type      = "number"
						title     = "Total"
						name      = "numberOfUnits"
						:value    = "transaction.numberOfUnits"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "select"
						title     = "Type"
						name      = "unitType"
						:options  = "TransactionUnitType"
						:value    = "transaction.unitType"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						:rounding = "1"
						title     = "Area"
						symbol    = "⏍"
						name      = "unitAreaSqft"
						:value    = "roundTo(transaction.unitAreaSqft, 1)"
						@input    = "onFieldInput"
						disabled
					/>
				</fieldset>

				<fieldset>
					<legend>restrictions</legend>

					<TransactionCardDetailedEditableField
						type      = "select"
						multiple
						title     = "CPTAQ"
						name      = "cptaq"
						:options  = "TransactionCPTAQ"
						:value    = "transaction.cptaq"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "select"
						multiple
						title     = "Urban Perimeter"
						name      = "urbanPerimeter"
						:options  = "TransactionUrbanPerimeter"
						:value    = "transaction.urbanPerimeter"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "select"
						multiple
						title     = "Infrastructure"
						name      = "infrastructure"
						:options  = "TransactionInfrastructure"
						:value    = "transaction.infrastructure"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "select"
						title     = "Land Shape"
						name      = "landShape"
						:options  = "TransactionLandShape"
						:value    = "transaction.landShape"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						title     = "Parcel Report"
						name      = "parcelReport"
						:value    = "transaction.parcelReport"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "select"
						title     = "Contamination"
						name      = "contamination"
						:options  = "TransactionContamination"
						:value    = "transaction.contamination"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						:rounding = "0.01"
						title     = "Contamination Cost"
						symbol    = "$"
						name      = "contaminationCost"
						:value    = "roundTo(transaction.contaminationCost, 0.01)"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "checkbox"
						title     = "Wetlands"
						name      = "wetlands"
						:value    = "transaction.wetlands"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "checkbox"
						title     = "Floodable Zone"
						name      = "floodableZone"
						:value    = "transaction.floodableZone"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "checkbox"
						title     = "Endangered Species"
						name      = "endangeredSpecies"
						:value    = "transaction.endangeredSpecies"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						:rounding = "0.1"
						title     = "Deduction"
						symbol    = "%"
						name      = "deductionRatio"
						:value    = "roundTo(transaction.deductionRatio, 0.1)"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "textarea"
						title     = "Deduction Comments"
						name      = "deductionComments"
						:value    = "transaction.deductionComments"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "select"
						title     = "Deduction Files"
						name      = "deductionFiles"
						:value    = "transaction.deductionFiles"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						title     = "Developable Land Area"
						symbol    = "⏍"
						name      = "developableLandAreaSqft"
						:value    = "roundTo(transaction.developableLandAreaSqft, 1)"
						@input    = "onFieldInput"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						:rounding = "0.1"
						title     = "Infrastructure Deduction"
						symbol    = "%"
						name      = "infrastructureDeductionRatio"
						:value    = "roundTo(transaction.infrastructureDeductionRatio, 0.1)"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "textarea"
						title     = "Infrastructure Deduction Comments"
						name      = "infrastructureDeductionComments"
						:value    = "transaction.infrastructureDeductionComments"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						title     = "Net Land Area"
						symbol    = "⏍"
						name      = "netLandAreaSqft"
						:value    = "roundTo(transaction.netLandAreaSqft, 1)"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
				</fieldset>

			</div>
		</div>
	</div>

	<dialog class="contact-modal" ref="contactDialog" @close="contactModalIsOpen = false">
		<form @submit.prevent>
			<input type="text" name="buyer" v-model="contactSearchPattern" autofocus autocomplete="off" />
			<button @click="handleAddContacts">add</button>
		</form>

		<ul class="contact-results">
			<li v-for="contact in contactResults" :key="contact.id" :data-id="contact.id" class="contact-result">
				<label :for="contact.id" class="details">
					<span class="name">{{ contact.firstName }} {{ contact.lastName }}</span>
					<div class="primary-company">
						<span>{{ contact.primaryCompany.name }}</span>
					</div>
					<span v-if="contact.address" class="address">{{ contact.address }}</span>
					<span v-if="!contact.address" class="unkown-address">{{ $t("unknownAddress") }}</span>
					<span class="email">{{ contact.email }}</span>
				</label>

				<input type="checkbox" :id="contact.id" @change="handleContactSelection" />
			</li>
		</ul>
	</dialog>
</template>

<script setup>
import { toRaw, computed, ref, watch } from "vue"
import { UseRootStore, Permission } from "@/model/RootStore"
import {
	TransactionType,
	TransactionTypeDefault,
	TransactionSaleContext,
	TransactionPartyType,
	TransactionLandSubtype,
	TransactionAuthorizedUse,
	TransactionCurrentState,
	TransactionCodificationType,
	TransactionProjectApproval,
	TransactionProjectApprovalAtDeedOfSale,
	TransactionProjectType,
	TransactionUnitType,
	TransactionCPTAQ,
	TransactionUrbanPerimeter,
	TransactionInfrastructure,
	TransactionLandShape,
	TransactionContamination,
	TransactionMetadataStatus,
} from "@/model/DataModel"
import {
	updateTransaction,
	searchContacts,
	addContactToTransaction,
	removeContactFromTransaction,
} from "@/utils/ApiClient"

import TransactionCardDetailedEditableField from "@/components/menu-right/TransactionCardDetailedEditableField.vue"

const Role = Object.freeze({
	Buyer: "buyer",
})

const transaction = defineModel("transaction", { type: Object, required: true })

console.debug(toRaw(transaction.value))

const contactDialog = ref(null)
const contactModalIsOpen = ref(false)
const contactResults = ref([])
const contactSearchPattern = ref("")
const selectedContacts = ref([])

watch(contactModalIsOpen, isOpen => {
	if (!isOpen) {
		contactResults.value = []
		contactSearchPattern.value = ""
		selectedContacts.value = []
		return
	}
	const {
		buyer: { name },
	} = transaction.value
	contactSearchPattern.value = name
	contactDialog.value.showModal()
})

watch(contactSearchPattern, async name => {
	if (name.length < 1) {
		contactResults.value = []
		return
	}
	contactResults.value = (await searchContacts(store.userApiToken, name)) || []
})

function handleContactSelection(event) {
	const checked = event.target.checked
	const contactId = event.target.id

	checked
		? selectedContacts.value.push(contactId)
		: (selectedContacts.value = selectedContacts.value.filter(id => id !== contactId))
}

async function handleAddContacts() {
	const errors = []
	const contactIds = selectedContacts.value
	const { inscriptionNumber } = transaction.value

	contactIds.forEach(async id => {
		const ok = await addContactToTransaction(store.userApiToken, inscriptionNumber, Role.Buyer, id)
		if (ok) {
			// TODO auto insert new contact once addContactToTransaction returns the contact object
			/* const contact = {} */
			/* transaction.contacts = [...transaction.contacts, contact] */
		} else {
			errors.push(id)
		}
	})
	if (errors.length > 0) {
		console.error(`Error adding contacts ${errors.join(", ")}`)
		// TODO display error (toggle error state ref to true)
	}
	contactDialog.value.close()
}

async function handleRemoveContact(transaction, contact) {
	const lines = []
	lines.push("Are you sure you want to remove:")
	lines.push("")
	lines.push(`${contact.firstName} ${contact.lastName} (${contact.email})`)
	lines.push("")
	if (contact.address) {
		lines.push(contact.address)
		lines.push("")
	}
	lines.push(`from transaction ${transaction.inscriptionNumber}?`)

	const text = lines.join("\r\n")
	const ok = window.confirm(text)
	if (ok) {
		const { inscriptionNumber } = transaction
		const contactId = contact.id
		const ok = removeContactFromTransaction(store.userApiToken, inscriptionNumber, contactId)
		if (ok) {
			const contacts = transaction.contacts.filter(({ id }) => id != contact.id)
			transaction.contacts = contacts
			return true
		}
		console.error(`Error removing contact ${contactId}`)
		return false
	}
}

const store = UseRootStore()

const isWriter = store.currentUser?.has(Permission.TransactionWrite)

function getUrlTrailingSegments(url, count = 1) {
	if (!url) {
		return null
	}

	const u = new URL(url)

	// Split the pathname into segments
	const segments = u.pathname.split("/").filter(Boolean)

	// Extract the last `count` segments
	return segments.slice(-count).join("/")
}

function spaceOutNumbers(...nums) {
	return nums
		.filter(Boolean)
		.map(String)
		.map(n => n.replace(/\B(?=(\d{3})+(?!\d))/g, " "))
}

function roundTo(value, multiple) {
	if (!value) return null
	return Math.round(value / multiple) * multiple
}

function onFieldInput({ name, value }) {
	if (!isWriter) {
		return
	}

	const { inscriptionNumber, presentationId } = transaction.value

	updateTransaction(store.userApiToken, inscriptionNumber, presentationId, {
		[name]: value,
	})
}

function onStatusChange(event) {
	const { inscriptionNumber, presentationId } = transaction.value
	const status = event.target.value

	updateTransaction(store.userApiToken, inscriptionNumber, presentationId, {
		metadataStatus: status,
	})
}

function handleAddToExportList(event, transaction) {
	const box = event.target
	const tr = toRaw(transaction)

	// prettier-ignore
	box.checked ?
		store.transactionExportList.set(tr.presentationId, tr) :
		store.transactionExportList.delete(tr.presentationId)
}
</script>

<style scoped>
@keyframes blink {
	50% {
		opacity: 0.2;
	}
}

.contact-modal {
	&:not([open]) {
		display: none;
	}

	/* center in screen */
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	width: 80vw;
	height: 80vh;

	display: flex;
	flex-direction: column;

	font-size: 150%;
	padding: 30px 50px;

	form {
		display: flex;
		justify-content: space-between;
		gap: 20px;

		font-size: 150%;
		margin-bottom: 30px;

		input {
			flex: 1;
			padding: 0.2em;
		}
		button {
			width: 100px;
			user-select: none;
		}
	}

	.contact-results {
		list-style: none;

		display: flex;
		flex-direction: column;

		.contact-result {
			display: flex;
			justify-content: space-between;
			position: relative;

			&:nth-child(odd) {
				background-color: #eee;
			}

			.details {
				display: flex;
				flex-direction: column;

				width: 100%;
				padding: 15px;
				gap: 5px;

				cursor: pointer;

				.name {
					font-weight: bold;
				}
				.unkown-address {
					font-style: italic;
					opacity: 0.5;
				}
			}

			input[type="checkbox"] {
				transform: scale(1.5);
				margin-right: 15px;
			}
		}
	}
}

.transaction {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 1.5rem;
	padding: 1.4rem 1.4rem 1.8rem 1.4rem;
	overflow: auto;
	background-color: white;

	& > .header {
		display: flex;
		flex-direction: row;
		gap: 0.4rem;
		min-height: 2.5em;

		input[type="checkbox"] {
			transform: scale(2);
			width: 10px;
			margin-left: 5px;
		}
		.title {
			margin: 0 40px 0 10px;

			.address {
				color: #000;
				font-size: 2.3rem;
				font-weight: 500;
				line-height: 2.53rem;

				&.unavailable {
					color: hsl(0 0 80);
					text-transform: capitalize;
				}
			}
		}
	}

	.borough {
		color: #777;
		font-size: 1.4rem;
		font-weight: 400;
		line-height: 1.8rem;
	}
	.separator {
		width: 100%;
		height: 0.15rem;
		background-color: #c4c4c4;
	}
	.items {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		gap: 2.4rem;

		header {
			display: flex;
			justify-content: space-around;
			align-items: center;

			.price {
				font-family: "basier_circlesemibold", sans-serif;
				font-size: 3.3rem;
				font-weight: 600;
				line-height: 3.63rem;

				.currency {
					color: black;
					opacity: 0.7;
				}
				.amount {
					color: var(--accent-color);
				}
			}
			.legacy-hint {
				color: red;
				opacity: 0.7;
				font-weight: bold;
				font-size: 150%;
				transform: translateY(3px);
				margin: 10px;
				animation: blink 0.4s linear infinite;
			}
			.status {
				margin-bottom: -5px;
				transform: scale(1.3);
				margin-left: 15px;

				select {
					padding: 3px;
				}
			}
		}
		.details {
			display: flex;
			flex-direction: column;
			gap: 3rem;

			fieldset {
				min-width: 0;

				display: flex;
				flex-direction: column;
				justify-content: space-between;
				gap: 1rem;

				border: 1px solid hsl(0deg 0% 80%);
				padding: 20px 10px 10px 10px;

				--color-bg-emphasis: hsl(0 0 95);
				--color-on-bg-emphasis: hsl(0 0 40);

				legend {
					font-size: 1.9em;
					text-transform: capitalize;
					color: var(--color-on-bg-emphasis);
					font-weight: bold;
				}

				h2 {
					display: flex;
					justify-content: center;
					gap: 5px;
					padding: 3px 0px;
					background-color: var(--color-bg-emphasis);
					color: var(--color-on-bg-emphasis);
					text-transform: capitalize;
					margin-top: 10px;

					&::before,
					&::after {
						content: "───";
					}
				}

				.contact-link {
					align-self: center;
					width: 200px;
					padding: 10px;
					font-size: 150%;
					color: black;
					background-color: var(--color-bg-emphasis);
					border: 1px solid gray;
					margin: 10px;

					&:not(:active) {
						box-shadow: 1px 1px 4px hsl(0 0 0 / 0.2);
					}
				}

				.contacts {
					display: flex;
					flex-direction: column;
					gap: 5px;

					.contact {
						display: flex;
						justify-content: space-between;

						/* border-bottom: 1px solid hsl(0 0 0 / 0.2); */
						padding: 0px 10px;

						&:hover {
							background: var(--color-bg-emphasis);
						}

						.info {
							display: flex;
							flex-direction: column;
							font-size: 130%;
							padding: 12px 0px;

							.name {
								font-weight: bold;
							}
							.email {
								color: hsl(0 0 0 / 0.5);
							}
						}
						.remove {
							flex: 0 1 30px;
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: 150%;
							color: var(--warning-color);
							cursor: pointer;
							user-select: none;
						}
					}
				}
			}
		}
	}
}
</style>
