<template>
	<article>
		<div id="map" />

		<map-search
			class="map-search"
			:map-center="mapCenter"
			v-model:selected-feature-id="selectedFeatureId"
			@search-by-address="handleSearchByAddress"
			@search-by-parcel="handleSearchByParcel"
			@search-by-transaction="handleSearchByTransaction"
		>
			<template #suggestion-type-title="{ item }">{{ $t(`search.${item}`) }}</template>
		</map-search>
	</article>
</template>

<script>
import MapSearch from "@/components/map/MapSearch.vue"
import * as Sentry from "@sentry/vue"
import { MAP_STYLES } from "@/components/constants.js"
import { UseRootStore, Permission } from "@/model/RootStore"
import { useProspectStore } from "@/stores/prospectStore.ts"
import { Borough } from "@/model/DataModel.ts"
import { config, Feature } from "@/AppConfig.ts"
import { storeToRefs } from "pinia"
import { onMounted, ref, shallowRef, toRef, toRaw, watch, inject } from "vue"
import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import { getListings, getTransactions } from "@/utils/ApiClient"
import circle from "@turf/circle"
import uniqBy from "lodash.uniqby"

console.info("Mapbox version ", mapboxgl.version)

function refreshSource(map, sourceId) {
	// Get the existing source object
	const source = map.getSource(sourceId)
	if (!source) {
		console.error(`Source with id '${sourceId}' not found`)
		return
	}

	// Get all layers that use this source
	const layers = map.getStyle().layers.filter(layer => layer.source === sourceId)

	// Save the source data
	const sourceData = map.getStyle().sources[sourceId]

	// Remove the source and its layers
	layers.forEach(layer => map.removeLayer(layer.id))
	map.removeSource(sourceId)

	// Re-add the source
	map.addSource(sourceId, sourceData)

	// Re-add the layers
	layers.forEach(layer => map.addLayer(layer))
}

const TILES_BASE_URL = import.meta.env.VITE_TILES_BASE_URL

const STYLE_IMPORTS = Object.freeze({
	[MAP_STYLES.SIMPLE]: "https://basemaps.cartocdn.com/gl/positron-gl-style/style.json",
	[MAP_STYLES.STANDARD]: "mapbox://styles/mapbox/standard",
	[MAP_STYLES.SATELLITE]: "mapbox://styles/mapbox/satellite-streets-v12",
})

function usesStandardStyle(map) {
	return map.getStyle().imports?.[0]?.id === "basemap"
}

function usesSatelliteStyle(map) {
	return map.getStyle().name === "Mapbox Satellite Streets"
}

export default {
	components: {
		MapSearch,
	},
	props: {
		currentStyle: {
			type: String,
			required: true,
		},
	},
	emits: ["loaded", "listing-selected"],
	setup(props, { emit }) {
		const store = UseRootStore()
		const {
			hoveredListingId,
			selectedListingId,
			selectedParcelID,
			selectedParcel,
			visibleLayers,
			cameraPosition,
			listings: storeListings,
			transactions: storeTransactions,
			transactionExportList,
			previewedTransaction,
			selectedTransaction,
			listingsInMapViewPort,
			filters,
		} = storeToRefs(store)

		const prospectStore = useProspectStore()
		const { prospects } = storeToRefs(prospectStore)

		const currentStyle = toRef(props, "currentStyle")
		const selectedFeatureId = ref(null)
		const marker = new mapboxgl.Marker({ color: "#315e26" })

		const isLargeScreen = inject("isLargeScreen")

		const mapRef = shallowRef(null)
		const mapCenter = ref({ lng: 0, lat: 0 })

		// TODO use TS interface, when available, to describe feature objects
		const clearHighlights = map => {
			// Clear parcel highlight
			map.setFeatureState(
				{
					source: `cadastre-source`,
					sourceLayer: "landerz_models.parcel",
					id: selectedFeatureId.value,
				},
				{ highlight: false }
			)

			// Clear off-market listing highlight
			map.setFeatureState(
				{
					source: "offmarket-source",
					id: selectedFeatureId.value,
				},
				{ highlight: false }
			)
		}

		const clearSelections = () => {
			selectedFeatureId.value = null
			selectedListingId.value = null
			selectedParcelID.value = null
			selectedParcel.value = null
			marker.remove()
		}

		function getParcelFeaturesById(...ids) {
			const allParcels = map.querySourceFeatures("cadastre-source", {
				sourceLayer: "landerz_models.parcel",
			})
			return [...new Set(allParcels)].filter(parcel => ids.includes(parcel.id))
		}

		// TODO use for all parcel highlighting in Map
		function highlightParcels(map, ...ids) {
			getParcelFeaturesById(...ids).forEach(parcel => {
				map.setFeatureState(
					{
						source: "cadastre-source",
						sourceLayer: "landerz_models.parcel",
						id: parcel.id,
					},
					{ highlight: true }
				)
			})
		}
		// TODO merge with clearHighlights()
		function unhighlightParcels(map, ...ids) {
			getParcelFeaturesById(...ids).forEach(parcel => {
				map.setFeatureState(
					{
						source: "cadastre-source",
						sourceLayer: "landerz_models.parcel",
						id: parcel.id,
					},
					{ highlight: false }
				)
			})
		}

		function setTransactionFeatureState(map, id, state) {
			const features = map.querySourceFeatures("transactions", {
				sourceLayer: "landerz_models.transaction",
			})
			const feature = features.find(f => f.id === id)

			map.setFeatureState(
				{
					source: "transactions",
					sourceLayer: "landerz_models.transaction",
					id: id,
				},
				{ ...state }
			)
		}

		const handleSearchByAddress = position => {
			const map = mapRef.value
			if (!map) {
				return
			}

			const { lng, lat, bounds, isRegion } = position

			if (isRegion) {
				map.fitBounds(bounds)
			} else {
				const target = {
					center: [lng, lat],
					zoom: 17,
				}
				isLargeScreen.value ? map.flyTo(target) : map.jumpTo(target)
			}

			// Set marker on clicked coordinates.
			//
			// We're doing this concurrently with flyTo/fitBounds so that the
			// marker is already present when the movement nears destination.
			// This adds a sense of targetting and clarity.
			marker.setLngLat({ lng, lat }).addTo(map)
		}

		const handleSearchByTransaction = transaction => {
			store.selectedTransaction = transaction
		}

		const handleSearchByParcel = (position, parcelId) => {
			const map = mapRef.value
			if (!map) {
				return
			}

			const { lng, lat } = position
			const center = [lng, lat]
			const zoom = 17

			map.flyTo({ center, zoom })

			// Clear any existing highlight
			clearHighlights(map)

			// Clear any active selection
			clearSelections()

			// Set marker on clicked coordinates.
			//
			// We're doing this concurrently with flyTo so that the marker is
			// already present when the movement nears destination. This adds a
			// sense of targetting and clarity.
			marker.setLngLat({ lng, lat }).addTo(map)

			// Highlight feature once flyTo ends.
			//
			// This is necessary because we cannot query a feature of the map
			// that isn't yet loaded or in the viewport. Once 'moveend'
			// triggers we know for sure the feature is accessible in the
			// source. Besides, it's desirable that the parcel be marked
			// selected only once flyTo has ended; this means for instance that
			// the parcel details panel will only be displayed after the
			// animation has ended.
			map.once("moveend", () => {
				const features = map.querySourceFeatures("cadastre-source", {
					sourceLayer: "landerz_models.parcel",
				})
				const feature = features.find(f => f.id === parcelId)

				// Highlight searched parcel feature
				map.setFeatureState(
					{
						source: `cadastre-source`,
						sourceLayer: "landerz_models.parcel",
						id: feature.id,
					},
					{ highlight: true }
				)

				// Set selection to current parcel
				selectedParcelID.value = parcelId
				selectedParcel.value = feature.properties
				selectedFeatureId.value = feature.id
			})
		}

		onMounted(() => {
			if (!mapboxgl.supported()) {
				Sentry.captureMessage("MaboxGL is not supported")
				alert(
					"This application requires WebGL, which your browser does not support. Please try again with a different browser. \n\nCette application utilise WebGL, qui n'est pas supporté par votre navigateur. Veuillez réessayer avec un autre navigateur."
				)
			}

			const map = new mapboxgl.Map({
				// HTML element id to insert mapbox into
				container: "map",

				// Mapbox API token
				accessToken: import.meta.env.VITE_MAPBOX_TOKEN,

				// A custom one is instead added below
				attributionControl: false,

				// Keeps camera position in URL hash
				hash: true,

				// Camera position to initialize map at
				center: {
					lng: cameraPosition.value.centerLongitude || -73.57786,
					lat: cameraPosition.value.centerLatitude || 45.49729,
				},
				zoom: cameraPosition.value.zoom || 15, // default: a zoom level that loads less parcels on the cadastre
				bearing: cameraPosition.value.bearing || 0,
				pitch: cameraPosition.value.pitch || 0,

				// Map style to use (e.g. mapbox standard, carto positron, mapbox satellite)
				style: STYLE_IMPORTS[currentStyle.value],

				// Callback run before every request to an external URL.
				// Used here to pass in the auth token to our API service for
				// authenticated routes.
				transformRequest: (url, resourceType) => {
					if (url.includes("v3-tiles") && store.userApiToken) {
						return {
							url: url,
							headers: { "x-api-token": store.userApiToken },
						}
					}
				},
			})

			// Add a compact attribution on the map (defaults to bottom right
			// corner). Add the "Landerz" string as well, as attribution for
			// the additional data layers.
			map.addControl(
				new mapboxgl.AttributionControl({
					compact: true,
					customAttribution: "Landerz",
				})
			)

			// Add zoom and rotation controls to the map.
			map.addControl(
				new mapboxgl.NavigationControl({
					showCompass: true,
					showZoom: false,
					visualizePitch: true,
				}),
				"bottom-right"
			)

			// For debugging, enabling these will provide useful visual and console feedback
			/* map.showPadding = true */
			/* map.showTileBoundaries = true; */
			/* map.on('zoom', () => { */
			/* 	console.log(map.getZoom().toFixed(2)) */
			/* }); */

			window.map = map
			window.store = store
			mapRef.value = map
			mapCenter.value = map.getCenter()

			map.on("styledata", () => {
				store.setLayers(
					map
						.getStyle()
						.layers.filter(layer => layer?.metadata?.custom)
						.map(layer => layer.id)
				)
			})

			map.on("load", async () => {
				emit("loaded", true)
			})

			let defaultTerrain = null
			let preventFlyTo = false

			map.on("style.load", async () => {
				// Base style configuration
				{
					if (usesStandardStyle(map)) {
						// NOTE these could instead be exposed as layers when
						// Mapbox Standard basemap is active
						map.setConfigProperty("basemap", "showRoadLabels", true)
						map.setConfigProperty("basemap", "showPlaceLabels", true)
						map.setConfigProperty("basemap", "showPointOfInterestLabels", true)
						map.setConfigProperty("basemap", "showTransitLabels", true)
						map.setConfigProperty("basemap", "lightPreset", "day,") // dusk, dawn, day, night
					}

					if (usesStandardStyle(map) || usesSatelliteStyle(map)) {
						map.addSource("mapbox-dem", {
							type: "raster-dem",
							url: "mapbox://mapbox.mapbox-terrain-dem-v1",
						})
						map.setTerrain({ source: "mapbox-dem" })
					}

					defaultTerrain = map.getTerrain()
				}

				// Add visual data layers, as mapbox sources and layers
				{
					// Cadastre
					map.addSource("cadastre-source", {
						type: "vector",
						tiles: [`${TILES_BASE_URL}/landerz_models.parcel/{z}/{x}/{y}.pbf`],
						promoteId: "parcel_id",
					})
					map.addLayer({
						id: "cadastre",
						source: "cadastre-source",
						"source-layer": "landerz_models.parcel",
						slot: "top",
						type: "fill",
						layout: {
							visibility: visibleLayers.value.includes("cadastre") ? "visible" : "none",
						},
						paint: {
							"fill-color": [
								"case",
								["boolean", ["feature-state", "highlight"], false],
								"rgba(29, 191, 115, 0.2)",
								"rgba(29, 191, 115, 0)",
							],
							"fill-outline-color": "#0bab50", // landerz green
						},
						minzoom: 13,
						metadata: { custom: true },
					})

					// Administrative Divisions - Regions
					map.addSource("administrative-divisions/regions-source", {
						type: "vector",
						tiles: [`${TILES_BASE_URL}/landerz_raw.dlayer_admin_regio_s/{z}/{x}/{y}.pbf`],
					})
					map.addLayer({
						id: "administrative-divisions/regions",
						source: "administrative-divisions/regions-source",
						"source-layer": "landerz_raw.dlayer_admin_regio_s",
						type: "fill",
						layout: {
							visibility: visibleLayers.value.includes("administrative-divisions/regions") ? "visible" : "none",
						},
						paint: {
							"fill-opacity": 0.2,
							// prettier-ignore
							"fill-color": [
								"match",
								["get", "res_nm_reg"],
								"Côte-Nord",                     "#f67088",
								"Laval",                         "#f77543",
								"Gaspésie–Îles-de-la-Madeleine", "#d58c31",
								"Capitale-Nationale",            "#bb9731",
								"Bas-Saint-Laurent",             "#a39f31",
								"Montréal",                      "#87a731",
								"Montérégie",                    "#4fb031",
								"Mauricie",                      "#32b072",
								"Laurentides",                   "#34ae90",
								"Nord-du-Québec",                "#35aca4",
								"Saguenay–Lac-Saint-Jean",       "#e0b4f9",
								"Lanaudière",                    "#38a8c9",
								"Centre-du-Québec",              "#3ba3ec",
								"Estrie",                        "#8994f4",
								"Chaudière-Appalaches",          "#ba82f4",
								"Outaouais",                     "#e766f4",
								"Abitibi-Témiscamingue",         "#f563d3",
								"black",
							],
						},
						metadata: { custom: true },
					})

					// Administrative Divisions - Region Labels
					map.addSource("administrative-divisions/region_labels-source", {
						type: "vector",
						tiles: [`${TILES_BASE_URL}/landerz_models.administrative_region_labels/{z}/{x}/{y}.pbf`],
					})
					map.addLayer({
						id: "administrative-divisions/region_labels",
						source: "administrative-divisions/region_labels-source",
						"source-layer": "landerz_models.administrative_region_labels",
						type: "symbol",
						layout: {
							"text-field": ["get", "name"],
							visibility: "none",
						},
						maxzoom: 8,
						metadata: { custom: true },
					})

					// Administrative Divisions - MRC
					map.addSource("administrative-divisions/rcms-source", {
						type: "vector",
						tiles: [`${TILES_BASE_URL}/landerz_raw.dlayer_admin_mrc_s/{z}/{x}/{y}.pbf`],
					})
					map.addLayer({
						id: "administrative-divisions/rcms",
						source: "administrative-divisions/rcms-source",
						"source-layer": "landerz_raw.dlayer_admin_mrc_s",
						type: "fill",
						layout: {
							visibility: visibleLayers.value.includes("administrative-divisions/rcms") ? "visible" : "none",
						},
						paint: {
							"fill-opacity": 0.2,
							// prettier-ignore
							"fill-color": [
								"match",
								["get", "mrs_nm_mrc"],
								"Argenteuil",                                   "#AF3936",
								"Papineau",                                     "#A16405",
								"Brome-Missisquoi",                             "#6E2154",
								"Shawinigan",                                   "#B19FEF",
								"La Nouvelle-Beauce",                           "#18BCC0",
								"Charlevoix-Est",                               "#7535C0",
								"Le Haut-Richelieu",                            "#A83BB9",
								"Joliette",                                     "#B3073C",
								"Montmagny",                                    "#1A45C7",
								"Le Val-Saint-François",                        "#D3B24C",
								"Vaudreuil-Soulanges",                          "#75DDBC",
								"Le Haut-Saint-François",                       "#1A1078",
								"Pierre-De Saurel",                             "#FDCBED",
								"La Tuque",                                     "#BF9448",
								"Les Collines-de-l'Outaouais",                  "#97A7B2",
								"Lévis",                                        "#96D305",
								"La Matapédia",                                 "#016BE6",
								"Abitibi-Ouest",                                "#120E0C",
								"Caniapiscau",                                  "#523B99",
								"La Haute-Côte-Nord",                           "#576114",
								"Témiscamingue",                                "#A1DA2D",
								"Sherbrooke",                                   "#C9E8B3",
								"La Vallée-de-l'Or",                            "#D1E5C1",
								"Gatineau",                                     "#280E76",
								"L'Assomption",                                 "#FB340A",
								"Laval",                                        "#7B0811",
								"L'Érable",                                     "#8FFFDF",
								"Communauté maritime des Îles-de-la-Madeleine", "#E45BDB",
								"Roussillon",                                   "#C86B03",
								"Maria-Chapdelaine",                            "#EC258B",
								"Antoine-Labelle",                              "#700B96",
								"Sept-Rivières",                                "#25DDB7",
								"Pontiac",                                      "#679C78",
								"Le Granit",                                    "#26F73F",
								"Les Etchemins",                                "#A4F261",
								"Minganie",                                     "#B673CF",
								"Jamésie",                                      "#38C0CC",
								"Bellechasse",                                  "#0DA381",
								"Les Chenaux",                                  "#449135",
								"Maskinongé",                                   "#B51D11",
								"Les Laurentides",                              "#4FD3C1",
								"Rouyn-Noranda",                                "#E94595",
								"Saguenay",                                     "#4C12AA",
								"L'Île-d'Orléans",                              "#CF2879",
								"Thérèse-De Blainville",                        "#18332E",
								"Abitibi",                                      "#31DE1C",
								"Kamouraska",                                   "#DF7D3C",
								"Administration régionale Kativik",             "#6E8090",
								"Arthabaska",                                   "#264E0E",
								"Rimouski-Neigette",                            "#AFAF89",
								"Coaticook",                                    "#7607FB",
								"Lotbinière",                                   "#05B598",
								"Bécancour",                                    "#5A6282",
								"Trois-Rivières",                               "#CE3ABD",
								"Mirabel",                                      "#02B5C8",
								"La Vallée-de-la-Gatineau",                     "#64A677",
								"Beauharnois-Salaberry",                        "#A242A0",
								"Les Sources",                                  "#4C0778",
								"L'Islet",                                      "#CF1383",
								"La Jacques-Cartier",                           "#67B2AF",
								"Lac-Saint-Jean-Est",                           "#770464",
								"La Matanie",                                   "#9E20B7",
								"Rouville",                                     "#097A4F",
								"Les Jardins-de-Napierville",                   "#F53328",
								"Les Appalaches",                               "#FE0235",
								"Beauce-Centre",                                "#9F1310",
								"La Côte-de-Beaupré",                           "#C73CDD",
								"Mékinac",                                      "#1B90B4",
								"Bonaventure",                                  "#FBC494",
								"Les Pays-d'en-Haut",                           "#BFBE29",
								"Memphrémagog",                                 "#760237",
								"Rivière-du-Loup",                              "#A19A4E",
								"La Vallée-du-Richelieu",                       "#ADF51E",
								"La Côte-de-Gaspé",                             "#2F48E3",
								"La Haute-Gaspésie",                            "#3C8479",
								"Le Golfe-du-Saint-Laurent",                    "#6DF83C",
								"Drummond",                                     "#42C9AA",
								"La Rivière-du-Nord",                           "#F33584",
								"La Haute-Yamaska",                             "#63953B",
								"Acton",                                        "#B29C4D",
								"Marguerite-D'Youville",                        "#1D1418",
								"Longueuil",                                    "#3FFFFA",
								"Matawinie",                                    "#1B8E77",
								"Le Rocher-Percé",                              "#ED6715",
								"Avignon",                                      "#4050E4",
								"Montcalm",                                     "#6471F9",
								"Beauce-Sartigan",                              "#1719C3",
								"Le Domaine-du-Roy",                            "#33A8A4",
								"Nicolet-Yamaska",                              "#05F2CD",
								"Portneuf",                                     "#13C2C7",
								"Le Haut-Saint-Laurent",                        "#890BED",
								"Deux-Montagnes",                               "#5EF372",
								"Le Fjord-du-Saguenay",                         "#07B816",
								"Québec",                                       "#634F33",
								"Les Moulins",                                  "#7C83EE",
								"La Mitis",                                     "#F8056E",
								"Nouveau toponyme à venir",                     "#788516",
								"Montréal",                                     "#3A2EFF",
								"Les Basques",                                  "#13808D",
								"Témiscouata",                                  "#22095E",
								"D'Autray",                                     "#6C371E",
								"Manicouagan",                                  "#1F28AB",
								"Les Maskoutains",                              "#831E7E",
								"Charlevoix",                                   "#8D17C9",
								"black",
							],
						},
						metadata: { custom: true },
					})

					// Administrative Divisions - MRC Labels
					map.addSource("administrative-divisions/rcm_labels-source", {
						type: "vector",
						tiles: [`${TILES_BASE_URL}/landerz_models.administrative_mrc_labels/{z}/{x}/{y}.pbf`],
					})
					map.addLayer({
						id: "administrative-divisions/rcm_labels",
						source: "administrative-divisions/rcm_labels-source",
						"source-layer": "landerz_models.administrative_mrc_labels",
						type: "symbol",
						layout: {
							"text-field": ["get", "name"],
							visibility: "none",
						},
						maxzoom: 12,
					})

					// Administrative Divisions - Metropolitan Areas
					map.addSource("administrative-divisions/metropolitan-areas-source", {
						type: "vector",
						tiles: [`${TILES_BASE_URL}/landerz_raw.dlayer_admin_comet_s/{z}/{x}/{y}.pbf`],
					})
					map.addLayer({
						id: "administrative-divisions/metropolitan-areas",
						source: "administrative-divisions/metropolitan-areas-source",
						"source-layer": "landerz_raw.dlayer_admin_comet_s",
						type: "fill",
						layout: {
							visibility: visibleLayers.value.includes("administrative-divisions/metropolitan-areas")
								? "visible"
								: "none",
						},
						paint: {
							"fill-opacity": 0.2,
							// prettier-ignore
							"fill-color": [
								"match",
								["get", "cms_nm_com"],
								"Communauté métropolitaine de Montréal", "#1dbf73",
								"Communauté métropolitaine de Québec",   "#a3c9ff",
								"black",
							],
						},
						metadata: { custom: true },
					})

					// Administrative Divisions - Municipalities
					map.addSource("administrative-divisions/municipalities-source", {
						type: "vector",
						tiles: [`${TILES_BASE_URL}/landerz_raw.dlayer_admin_munic_s/{z}/{x}/{y}.pbf`],
					})
					map.addLayer({
						id: "administrative-divisions/municipalities",
						source: "administrative-divisions/municipalities-source",
						"source-layer": "landerz_raw.dlayer_admin_munic_s",
						type: "line",
						layout: {
							visibility: visibleLayers.value.includes("administrative-divisions/municipalities") ? "visible" : "none",
						},
						paint: {
							"line-color": "#1E90FF", // Dodger Blue
							"line-width": 2,
						},
						minzoom: 9,
						metadata: { custom: true },
					})

					// Administrative Divisions - Municipality Labels
					map.addSource("administrative-divisions/municipality_labels-source", {
						type: "vector",
						tiles: [`${TILES_BASE_URL}/landerz_models.administrative_municipality_labels/{z}/{x}/{y}.pbf`],
					})
					map.addLayer({
						id: "administrative-divisions/municipality_labels",
						source: "administrative-divisions/municipality_labels-source",
						"source-layer": "landerz_models.administrative_municipality_labels",
						type: "symbol",
						layout: {
							"text-field": ["get", "name"],
							visibility: "none",
						},
						minzoom: 9,
						metadata: { custom: true },
					})

					// Administrative Divisions - Boroughs
					map.addSource("administrative-divisions/boroughs-source", {
						type: "vector",
						tiles: [`${TILES_BASE_URL}/landerz_raw.dlayer_admin_arron_s/{z}/{x}/{y}.pbf`],
					})
					map.addLayer({
						id: "administrative-divisions/boroughs",
						source: "administrative-divisions/boroughs-source",
						"source-layer": "landerz_raw.dlayer_admin_arron_s",
						type: "fill",
						layout: {
							visibility: visibleLayers.value.includes("administrative-divisions/boroughs") ? "visible" : "none",
						},
						paint: {
							"fill-opacity": 0.2,
							// prettier-ignore
							"fill-color": [
								"match",
								["get", "ars_nm_arr"],
								Borough.AhuntsicCartierville,                "#2011D3",
								Borough.Anjou,                               "#4F5528",
								Borough.Arrondissement1,                     "#1D0CA6",
								Borough.Arrondissement2,                     "#A34CCD",
								Borough.Arrondissement3,                     "#ABC5A4",
								Borough.Arrondissement4,                     "#7CCAF6",
								Borough.Beauport,                            "#1857BF",
								Borough.Calumet,                             "#682667",
								Borough.Charlesbourg,                        "#3E1DF5",
								Borough.Chicoutimi,                          "#96C295",
								Borough.CoteDesNeigesNotreDameDeGrace,       "#FC47BA",
								Borough.Desjardins,                          "#02A5F8",
								Borough.GreenfieldPark,                      "#30D610",
								Borough.Grenville,                           "#24432E",
								Borough.Jonquiere,                           "#D0A098",
								Borough.LIleBizardSainteGenevieve,           "#51C42F",
								Borough.LaBaie,                              "#A24631",
								Borough.LaCiteLimoilou,                      "#7E1B0F",
								Borough.LaHauteSaintCharles,                 "#7D9945",
								Borough.LaSalle,                             "#32412E",
								Borough.Lachine,                             "#7F74FD",
								Borough.LePlateauMontRoyal,                  "#70721C",
								Borough.LeSudOuest,                          "#65A189",
								Borough.LeVieuxLongueuil,                    "#A3FCF8",
								Borough.LesChutesDeLaChaudiereEst,           "#A6AA33",
								Borough.LesChutesDeLaChaudiereOuest,         "#890A9A",
								Borough.LesRivieres,                         "#6956E7",
								Borough.MacNider,                            "#C1DCD0",
								Borough.MercierHochelagaMaisonneuve,         "#C961C0",
								Borough.MontrealNord,                        "#FEBAB3",
								Borough.Outremont,                           "#7031F3",
								Borough.PierrefondsRoxboro,                  "#B01623",
								Borough.RivieredesPrairiesPointeAuxTrembles, "#68FFF2",
								Borough.RosemontLaPetitePatrie,              "#5005E1",
								Borough.SaintHubert,                         "#4D0172",
								Borough.SaintLaurent,                        "#50DA1F",
								Borough.SaintLeonard,                        "#001EB3",
								Borough.SainteFoySilleryCapRouge,            "#E9D8A3",
								Borough.Verdun,                              "#77411F",
								Borough.VilleMarie,                          "#BF0AB4",
								Borough.VilleraySaintMichelParcExtension,    "#565713",
								"black",
							],
						},
						metadata: { custom: true },
					})

					map.addSource("administrative-divisions/postal-codes-source", {
						type: "vector",
						tiles: [`${TILES_BASE_URL}/landerz_raw.dlayer_postal_codes_localdeliveryunitsregion/{z}/{x}/{y}.pbf`],
					})
					map.addLayer({
						id: "administrative-divisions/postal-codes",
						source: "administrative-divisions/postal-codes-source",
						"source-layer": "landerz_raw.dlayer_postal_codes_localdeliveryunitsregion",
						type: "line",
						layout: {
							visibility: visibleLayers.value.includes("administrative-divisions/postal-codes") ? "visible" : "none",
						},
						paint: {
							"line-color": "#ff9999", // salmon pink
							"line-opacity": 0.75,
						},
						minzoom: 13, // same as postal-code_labels
						metadata: { custom: true },
					})

					map.addSource("administrative-divisions/postal-code_labels-source", {
						type: "vector",
						tiles: [`${TILES_BASE_URL}/landerz_models.postalcode_labels/{z}/{x}/{y}.pbf`],
					})
					map.addLayer({
						id: "administrative-divisions/postal-code_labels",
						source: "administrative-divisions/postal-code_labels-source",
						"source-layer": "landerz_models.postalcode_labels",
						type: "symbol",
						layout: {
							"text-field": ["get", "postalcode"],
							visibility: visibleLayers.value.includes("administrative-divisions/postal-code_labels")
								? "visible"
								: "none",
						},
						minzoom: 13, // same as postal-codes
						metadata: { custom: true },
					})

					map.addSource("urban-planning-source", {
						type: "vector",
						tiles: [`${TILES_BASE_URL}/landerz_models.urban_plan_polygons/{z}/{x}/{y}.pbf`],
					})
					map.addLayer({
						id: "urban-planning",
						source: "urban-planning-source",
						"source-layer": "landerz_models.urban_plan_polygons",
						type: "fill",
						layout: {
							visibility: visibleLayers.value.includes("urban-planning") ? "visible" : "none",
						},
						paint: {
							"fill-opacity": 0.2,
							// prettier-ignore
							"fill-color": [
								"match",
								["get", "affectation"],
								"aeroportuaire",      "#B0C4DE", // light steel blue (representing the sky)
								"industrielle",       "#FFD700", // gold (industrial)
								"villegiature",       "#ADD8E6", // light blue (relaxation by the water)
								"urbaine",            "#C0C0C0", // silver (urban structures)
								"multifonctionnelle", "#FFDAB9", // peach puff (mixed-use, varied)
								"recreative",         "#98FB98", // pale green (recreational parks)
								"rurale",             "#F0E68C", // khaki (fields, rural areas)
								"extraction",         "#FFCC99", // light orange (mining, extraction)
								"forestiere",         "#90EE90", // light green (forests)
								"commerciale",        "#F08080", // light coral (commercial zones)
								"conservation",       "#66CDAA", // medium aquamarine (conservation areas)
								"agricole",           "#ADFF2F", // green yellow (agriculture, fields)
								"residentielle",      "#FFB6C1", // light pink (residential areas)
								"utilité",            "#B0C4DE", // light steel blue (utility areas)
								"agro-forestiere",    "#8FBC8F", // light sea green (agro-forestry)
								"rgba(0, 0, 0, 0)",
							],
						},
						metadata: { custom: true },
					})

					map.addSource("land-use-source", {
						type: "vector",
						tiles: [`${TILES_BASE_URL}/landerz_models.dlayer_land_use/{z}/{x}/{y}.pbf`],
					})
					map.addLayer({
						id: "land-use",
						source: "land-use-source",
						"source-layer": "landerz_models.dlayer_land_use",
						type: "fill",
						layout: {
							visibility: visibleLayers.value.includes("land-use") ? "visible" : "none",
						},
						paint: {
							"fill-opacity": 0.2,
							"fill-color": "#FF69B4", // Hot Pink
						},
						metadata: { custom: true },
					})

					map.addSource("agricultural-zones-source", {
						type: "vector",
						tiles: [`${TILES_BASE_URL}/landerz_models.dlayer_agricultural_zone/{z}/{x}/{y}.pbf`],
					})
					map.addLayer({
						id: "agricultural-zones",
						source: "agricultural-zones-source",
						"source-layer": "landerz_models.dlayer_agricultural_zone",
						type: "fill",
						layout: {
							visibility: visibleLayers.value.includes("agricultural-zones") ? "visible" : "none",
						},
						paint: {
							"fill-opacity": 0.2,
							// prettier-ignore
							"fill-color": [
								"match",
								["get", "category"],
								"agricultural", "#367C2B", //  green (agricultural)
								"black",
							],
						},
						metadata: { custom: true },
					})

					map.addSource("floodable-zones-source", {
						tiles: [`${TILES_BASE_URL}/landerz_models.floodable_area_polygons/{z}/{x}/{y}.pbf`],
						type: "vector",
					})
					map.addLayer({
						id: "floodable-zones",
						source: "floodable-zones-source",
						"source-layer": "landerz_models.floodable_area_polygons",
						type: "fill",
						layout: {
							visibility: visibleLayers.value.includes("floodable-zones") ? "visible" : "none",
						},
						paint: {
							"fill-opacity": 0.2,
							"fill-color": "#00BFFF",
						},
						metadata: { custom: true },
					})

					map.addSource("wetlands-source", {
						type: "vector",
						tiles: [`${TILES_BASE_URL}/landerz_models.milieux_humides_sudqc_2022/{z}/{x}/{y}.pbf`],
					})
					map.addLayer({
						id: "wetlands",
						source: "wetlands-source",
						"source-layer": "landerz_models.milieux_humides_sudqc_2022",
						type: "fill",
						layout: {
							visibility: visibleLayers.value.includes("wetlands") ? "visible" : "none",
						},
						paint: {
							"fill-opacity": 0.2,
							"fill-color": "#556B2F",
						},
						minzoom: 13,
						metadata: { custom: true },
					})

					map.addSource("prospects-source", {
						type: "vector",
						tiles: [`${TILES_BASE_URL}/landerz_models.parcel_prospects/{z}/{x}/{y}.pbf`],
					})
					map.addLayer({
						id: "prospects",
						source: "prospects-source",
						"source-layer": "landerz_models.parcel_prospects",
						type: "fill",
						layout: {
							visibility: visibleLayers.value.includes("prospects") ? "visible" : "none",
						},
						paint: {
							"fill-opacity": ["case", ["boolean", ["feature-state", "transparent"], false], 0, 0.5],
							// prettier-ignore
							"fill-color": [
								"match",
								["get", "potential"],
								"High",        "#C83238",
								"Medium",      "#F3B41E",
								"Low",         "#1576B8",
								"None",        "#333333",
								"Unspecified", "transparent",
								"black",
							],
						},
						minzoom: 13,
						metadata: { custom: true },
					})
				}

				// Add listing sources and layers
				//
				// Listings comprise landerz and off-market entries, each
				// with its source, and layers to display their icons.
				//
				// Additionally, there is a layer to display shadow circles
				// around the off-market listings, marking the radius within
				// which the obfuscated listing's location might lie.
				{
					// fetch the listing data from the API
					const fetchedListings = await getListings()

					storeListings.value = fetchedListings
					listingsInMapViewPort.value = fetchedListings

					// create a GeoJSON feature collection for all the fetched listings
					map.addSource("listings-source", {
						type: "geojson",
						data: {
							type: "FeatureCollection",
							features: fetchedListings.map(listing => ({
								type: "Feature",
								properties: {
									...listing,
									typeDev: listing.typeDevArray,
								},
								geometry: {
									coordinates: [listing.longitude, listing.latitude],
									type: "Point",
								},
							})),
						},
						promoteId: "id",
					})

					// Add a source for the range circle around offmarket listings
					{
						/* const offMarketFeatures = map.getStyle().sources["offmarket-source"].data.features */
						const offMarketFeatures = map
							.getStyle()
							.sources["listings-source"].data.features.filter(listing => listing.properties["type"] === "off_market")

						map.addSource("offmarket-source", {
							type: "geojson",
							data: {
								type: "FeatureCollection",
								features: offMarketFeatures.map(feature => {
									const coords = feature.geometry.coordinates
									const radius = Number(feature.properties["off_market_radius"])

									return circle(coords, radius, {
										steps: 64,
										units: "meters",
										properties: feature.properties,
									})
								}),
							},
							promoteId: "id", // must be the same as listings-source features
						})

						map.addLayer({
							id: "offmarket-area",
							source: "offmarket-source",
							type: "fill",
							paint: {
								"fill-color": "#888888",
								"fill-opacity": [
									"case",
									["boolean", ["feature-state", "highlight"], false],
									0.1, // Opacity when highlight is true
									0, // Opacity when highlight is false or undefined
								],
							},
						})
					}

					// Provide listing icon images
					{
						map.loadImage("/assets-flags/flag-off_market-new.png", (error, image) => {
							if (error) throw error
							map.addImage("custom-marker-offmarket-new", image)
						})
						map.loadImage("/assets-flags/flag-off_market.png", (error, image) => {
							if (error) throw error
							map.addImage("custom-marker-offmarket", image)
						})

						map.loadImage("/assets-flags/flag-landerz-new.png", (error, image) => {
							if (error) throw error
							map.addImage("custom-marker-landerz-new", image)
						})
						map.loadImage("/assets-flags/flag-landerz.png", (error, image) => {
							if (error) throw error
							map.addImage("custom-marker-landerz", image)
						})

						map.loadImage("/assets-flags/flag-landerz-offer.png", (error, image) => {
							if (error) throw error
							map.addImage("custom-marker-landerz-offer", image)
						})
						map.loadImage("/assets-flags/flag-landerz-offer-new.png", (error, image) => {
							if (error) throw error
							map.addImage("custom-marker-landerz-offer-new", image)
						})

						map.loadImage("/assets-flags/flag-external-new.png", (error, image) => {
							if (error) throw error
							map.addImage("custom-marker-external-new", image)
						})

						map.loadImage("/assets-flags/flag-external.png", (error, image) => {
							if (error) throw error
							map.addImage("custom-marker-external", image)
						})
					}

					// Associate icons to listings, based on each listing type
					map.addLayer({
						id: "listings-icons",
						type: "symbol",
						source: "listings-source",
						layout: {
							"icon-image": [
								"case",
								[
									"all",
									["==", ["get", "type"], "landerz"],
									["==", ["get", "sales_process_status"], "accepted_psa"],
									[">", ["get", "launch_date_ms"], ["to-number", Date.now() - 2592000000]],
								],
								"custom-marker-landerz-offer-new",

								["all", ["==", ["get", "type"], "landerz"], ["==", ["get", "sales_process_status"], "accepted_psa"]],
								"custom-marker-landerz-offer",

								[
									"all",
									["==", ["get", "type"], "landerz"],
									[">", ["get", "launch_date_ms"], ["to-number", Date.now() - 2592000000]],
								],
								"custom-marker-landerz-new",

								["==", ["get", "type"], "landerz"],
								"custom-marker-landerz",

								[
									"all",
									["==", ["get", "type"], "off_market"],
									[">", ["get", "launch_date_ms"], ["to-number", Date.now() - 2592000000]],
								],
								"custom-marker-offmarket-new",

								["==", ["get", "type"], "off_market"],
								"custom-marker-offmarket",

								[
									"all",
									["==", ["get", "type"], "external"],
									[">", ["get", "launch_date_ms"], ["to-number", Date.now() - 2592000000]],
								],
								"custom-marker-external-new",

								["==", ["get", "type"], "external"],
								"custom-marker-external",

								"custom-marker",
							],
							"icon-size": 0.07,
							"icon-allow-overlap": true,
						},
					})
				}

				// Load transaction icons
				{
					const images = [
						{ url: "icons/transaction.png", name: "transaction" },
						{ url: "icons/transaction-included.png", name: "transaction-included" },
						{ url: "icons/transaction-excluded.png", name: "transaction-excluded" },
					]
					await Promise.all(
						images.map(
							img =>
								new Promise((resolve, reject) => {
									map.loadImage(img.url, (error, data) => {
										if (error) {
											reject(error)
											return
										}
										if (!map.hasImage(img.name)) {
											map.addImage(img.name, data)
											resolve()
										}
									})
								})
						)
					)
				}

				// Add transaction sources and layers
				if (store.currentUser?.has(Permission.TransactionRead) && config.ui.has(Feature.Transactions)) {
					if (config.ui.has(Feature.TransactionSourceAPI)) {
						const transactions = await getTransactions(store.userApiToken)
						storeTransactions.value = transactions

						// Create a GeoJSON feature collection for all the fetched transactions
						map.addSource("transactions", {
							type: "geojson",
							data: {
								type: "FeatureCollection",
								features: transactions.map(transaction => ({
									type: "Feature",
									id: transaction.id,
									properties: {
										id: transaction.id,
										date: transaction.deal.registryDate?.getTime() || Date.parse("01 Jan 2000 00:00:00 UTC"),
										salePrice: transaction.deal.salePrice || 0,
										superficy: transaction.location.area || 0,
										municipality: transaction.location.municipality,
										borough: transaction.location.borough,
									},
									geometry: {
										type: "Point",
										coordinates: [
											parseFloat(transaction.location.coordinates.longitude),
											parseFloat(transaction.location.coordinates.latitude),
										],
									},
								})),
							},
							promoteId: "id",
						})

						// Associate icons to transactions
						map.addLayer(
							{
								id: "transaction-icons",
								type: "symbol",
								source: "transactions",
								layout: {
									"icon-image": "transaction", // default icon
									"icon-size": 0.45,
									"icon-allow-overlap": true,
								},
							},
							"listings-icons"
						)
					} else if (config.ui.has(Feature.TransactionSourceTiles)) {
						map.addSource("transactions", {
							type: "vector",
							tiles: [`${TILES_BASE_URL}/landerz_models.transaction/{z}/{x}/{y}.pbf`],
							promoteId: "presentation_id",
						})
						map.addLayer({
							id: "transaction-fill",
							source: "transactions",
							"source-layer": "landerz_models.transaction",
							type: "fill",
							paint: {
								"fill-color": "#FFA500", // orange
								"fill-opacity": [
									"interpolate",
									["linear"],
									["zoom"],
									10, // at zoom level 10 ⤵
									[
										"case",
										[
											"any",
											["boolean", ["feature-state", "highlight"], false],
											["boolean", ["feature-state", "preview"], false],
										],
										1.0, // highlight / preview opacity
										0.5, // default opacity
									],
									13, // at zoom level 13 ⤵
									[
										"case",
										[
											"any",
											["boolean", ["feature-state", "highlight"], false],
											["boolean", ["feature-state", "preview"], false],
										],
										0.5, // highlight / preview opacity
										0.2, // default opacity
									],
								],
							},
						})
					} else {
						throw new Error(`unknown data source for transactions`)
					}
				}

				// prettier-ignore
				watch(filters, (newFilters) => {
					const layers                 = newFilters.layers
					const rangeFilters           = newFilters.rangeFilters
					const devTypes               = toRaw(newFilters.devTypes)
					const municipalities         = toRaw(newFilters.municipalities)
					const boroughs               = toRaw(newFilters.boroughs)
					const transactionTypes       = toRaw(newFilters.transactionTypes)
					const transactionStatuses    = toRaw(newFilters.transactionStatuses)
					const transactionCategories  = toRaw(newFilters.transactionCategories)
					const transactionLegacyState = toRaw(newFilters.transactionLegacyState)

					map.setFilter("listings-icons", [
						"all",
						["in", ["get", "type"], ["literal", layers]],
						[">=", ["get", "superficy"], rangeFilters.superficy.min],
						["<=", ["get", "superficy"], rangeFilters.superficy.max],
						[">=", ["get", "constructible"], rangeFilters.constructible.min],
						["<=", ["get", "constructible"], rangeFilters.constructible.max],
						[">=", ["get", "price"], rangeFilters.price.min],
						["<=", ["get", "price"], rangeFilters.price.max],

						// match if any item in the listing property `typeDev`
						// matches any item in the devTypes filter
						["any", ...devTypes.map(type => ["in", type, ["get", "typeDev"]])],
					])

					if (store.currentUser?.has(Permission.TransactionRead) && config.ui.has(Feature.Transactions)) {
						if (config.ui.has(Feature.TransactionSourceAPI)) {

							// TODO the layer name should be either "transaction" or
							// "transactions", find source and fix
							(layers.includes("transaction") || layers.includes("transactions")) ?
								map.setLayoutProperty("transaction-icons", "visibility", "visible") :
								map.setLayoutProperty("transaction-icons", "visibility", "none")

							map.setFilter("transaction-icons", [
								"all",
								[">=", ["get", "salePrice"], rangeFilters.price.min],
								["<=", ["get", "salePrice"], rangeFilters.price.max],
								[">=", ["get", "date"], rangeFilters.date.min.getTime()],
								["<=", ["get", "date"], rangeFilters.date.max.getTime()],
								[">=", ["get", "superficy"], rangeFilters.superficy.min],
								["<=", ["get", "superficy"], rangeFilters.superficy.max],

								municipalities.length > 0 ?
									["in", ["get", "municipality"], ["literal", municipalities]] :
									true,

								boroughs.length > 0 ?
									["in", ["get", "borough"], ["literal", boroughs]] :
									true,
							])
							// NOTE transactionTypes filter missing
							// NOTE transactionStatuses filter missing
							// NOTE transactionCategories filter missing
							// NOTE transactionLegacyState filter missing
						} else if (config.ui.has(Feature.TransactionSourceTiles) && map.getLayer("transaction-fill")) {

							// TODO the layer name should be either "transaction" or
							// "transactions", find source and fix
							;(layers.includes("transaction") || layers.includes("transactions")) ?
								map.setLayoutProperty("transaction-fill", "visibility", "visible") :
								map.setLayoutProperty("transaction-fill", "visibility", "none")

							// Format range filter dates to "YYYY-MM-DD HH:mm:ss"
							function formatDate(date) {
								return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")} ` +
									`${String(date.getHours()).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}:${String(date.getSeconds()).padStart(2, "0")}`;
							}
							map.setFilter("transaction-fill", [
								"all",
								[">=", ["to-number", ["get", "salePrice"]], rangeFilters.price.min],
								["<=", ["to-number", ["get", "salePrice"]], rangeFilters.price.max],
								[">=", ["get", "deed_of_sale_date"], formatDate(rangeFilters.date.min)],
								["<=", ["get", "deed_of_sale_date"], formatDate(rangeFilters.date.max)],
								[
									"any",
									["!", ["has", "area_square_feet"]], // Include if area_square_feet is not present
									[
										"all",
										[">=", ["to-number", ["get", "area_square_feet"]], rangeFilters.superficy.min],
										["<=", ["to-number", ["get", "area_square_feet"]], rangeFilters.superficy.max]
									]
								],

								municipalities.length > 0 ?
									["in", ["get", "municipality_name"], ["literal", municipalities]] :
									true,

								boroughs.length > 0 ?
									["in", ["get", "borough"], ["literal", boroughs]] :
									true,

								store.currentUser?.has(Permission.TransactionWrite) ?
									// Writers get to filter by transaction type
									(transactionTypes.length > 0 ?
										["in", ["get", "type"], ["literal", transactionTypes]] :
										true) :
									// Non-writers only get to see transactions of type land
									// TODO "Land" should be a const. Currently
									// TransactionType (in DataModel.ts) is an array, it
									// could be an enum instead and then "Land"
									// becomes TransactionType.Land
									["==", ["get", "type"], "Land"],

								store.currentUser?.has(Permission.TransactionWrite) ?
									// Writers get to filter by transaction type
									(transactionStatuses.length > 0 ?
										["in", ["get", "metadata_status"], ["literal", transactionStatuses]] :
										true) :
									true,

								store.currentUser?.has(Permission.TransactionWrite) ?
									// Writers get to filter by transaction type
									(transactionCategories.length > 0 ?
										["in", ["get", "category"], ["literal", transactionCategories]] :
										true) :
									true,

								store.currentUser?.has(Permission.TransactionWrite) ?
									(transactionLegacyState === null ? true :
										 ["==", ["get", "has_legacy"], transactionLegacyState]) :
									 true,
							])
						}
					}
				}, {
					deep: true,
					immediate: true,
				});
			})

			// Update transaction icons based on export list status.
			//
			// NOTE
			// This is somewhat of a workaround to a built-in limitation;
			// idealy this would be implemented as a "feature-state"-based
			// expression directly in the "transaction-icons" layer.
			// Unfortunately, "feature-state" does not support "layout"
			// properties, only "paint" properties. An often proposed
			// alternative seems to be dynamically rebuilding the source with
			// altered properties (say in this case, setting
			// `properties.comparable = 'include'`), but this presents
			// potential performance downsides, along with maintenance
			// complexity. This alternative creates an expression that simply
			// matches against transaction ids, and rebuilds the expression
			// whenever a related list changes.
			//
			// See (FeatureState support for Layout)[https://github.com/mapbox/mapbox-gl-js/issues/9303#issuecomment-1660167912]
			// See (watch() multiple sources)[https://vuejs.org/guide/essentials/watchers.html#watch-source-types]
			watch(
				transactionExportList,
				transactions => {
					if (config.ui.has(Feature.TransactionSourceAPI)) {
						// Extract ids from the export list
						const ids = [...transactions].map(transaction => transaction.id)

						map.setLayoutProperty("transaction-icons", "icon-image", [
							"case",
							["in", ["get", "id"], ["literal", ids]],
							"transaction-included", // icon for transactions included in export list
							"transaction", // fallback icon
						])
					} else if (config.ui.has(Feature.TransactionSourceTiles)) {
						// ...
					}
				},
				{ deep: true }
			)

			map.on("pitch", () => {
				if (map.getPitch() === 0) {
					// This is a fix to get layers to obey "slot: 'top'".
					// see: https://github.com/mapbox/mapbox-gl-js/issues/13036#issuecomment-1883702763
					map.setTerrain(null)
				} else {
					map.setTerrain(defaultTerrain)
				}
			})

			map.on("idle", () => {
				const listingFeaturesViewPort = map
					.queryRenderedFeatures({
						layers: ["listings-icons"],
					})
					.map(({ properties }) => properties)

				listingsInMapViewPort.value = uniqBy(listingFeaturesViewPort, "id")
			})

			map.on("moveend", () => {
				const cameraPosition = window.location.hash.replace("#", "")
				store.setCameraPosition(cameraPosition)

				mapCenter.value = map.getCenter()
			})

			// Display appropriate cursor over icons
			{
				const layers = ["listings-icons", "transaction-icons"]
				map.on("mouseenter", layers, () => {
					map.getCanvas().style.cursor = "pointer"
				})
				map.on("mouseleave", layers, () => {
					map.getCanvas().style.cursor = ""
				})
			}

			// Handle mouse hover over transaction icon or area (preview)
			if (store.currentUser?.has(Permission.TransactionRead) && config.ui.has(Feature.Transactions)) {
				if (config.ui.has(Feature.TransactionSourceAPI)) {
					map.on("mouseenter", "transaction-icons", event => {
						previewedTransaction.value = toRaw(store.getTransactionById(event.features[0].id))
					})
					map.on("mouseleave", "transaction-icons", () => {
						previewedTransaction.value = null
					})
				} else if (config.ui.has(Feature.TransactionSourceTiles)) {
					map.on("mouseenter", "transaction-fill", async event => {
						previewedTransaction.value = event.features[0].id
					})
					map.on("mouseleave", "transaction-fill", () => {
						previewedTransaction.value = null
					})
				}
			}

			// Display obfuscated location area for off market listings
			map.on("mouseenter", "listings-icons", event => {
				const feature = event.features[0]

				map.setFeatureState(
					{
						source: "offmarket-source",
						id: feature.id,
					},
					{ highlight: true }
				)
			})
			map.on("mouseleave", "listings-icons", event => {
				const features = map.querySourceFeatures("offmarket-source")
				features.forEach(feature => {
					map.setFeatureState({ source: "offmarket-source", id: feature.id }, { highlight: false })
				})
			})

			// NOTE the order of click handlers matters; they will be called in
			// the order they are defined.

			map.on("click", "transaction-icons", event => {
				if (event.stopMapPropagation) {
					return
				}
				event.stopMapPropagation = true

				// update transaction
				const transaction = toRaw(store.getTransactionById(event.features[0].id))
				store.updateSelectedTransaction(transaction)
			})

			map.on("click", "transaction-fill", async event => {
				if (event.stopMapPropagation) {
					return
				}
				event.stopMapPropagation = true
				preventFlyTo = true

				// update transaction
				const prevCursor = map.getCanvas().style.cursor
				map.getCanvas().style.cursor = "wait"
				const transaction = await store.getHydratedTransactionByPresentationId(event.features[0].id)
				map.getCanvas().style.cursor = prevCursor
				store.updateSelectedTransaction(transaction)
			})

			map.on("click", "listings-icons", event => {
				if (event.stopMapPropagation) {
					return
				}
				event.stopMapPropagation = true

				const feature = structuredClone(event.features[0])

				// Clear any existing highlight
				clearHighlights(map)

				// Clear any active selection
				clearSelections()

				// Set selection to current listing
				selectedListingId.value = feature.id
				selectedFeatureId.value = feature.id

				emit("listing-selected", feature.properties)
			})

			map.on("click", "cadastre", event => {
				if (event.stopMapPropagation) {
					return
				}
				event.stopMapPropagation = true

				const feature = structuredClone(event.features[0])

				// Clear any existing highlight
				clearHighlights(map)

				// Clear any active selection
				clearSelections()

				// Highlight the newly selected parcel
				map.setFeatureState(
					{
						source: `cadastre-source`,
						sourceLayer: "landerz_models.parcel",
						id: feature.id,
					},
					{ highlight: true }
				)

				// Set marker on clicked coordinates
				const lat = structuredClone(event.lngLat.lat)
				const lng = structuredClone(event.lngLat.lng)
				marker.setLngLat({ lat, lng }).addTo(map)

				// Set selection to current parcel
				// TODO why both parcel and parcelID?
				// TODO rename parcelID to parcelId, using js convention
				const parcelId = feature.properties["parcel_id"]
				selectedParcelID.value = parcelId
				selectedParcel.value = feature.properties
				selectedFeatureId.value = feature.id
			})

			map.on("click", event => {
				if (event.stopMapPropagation) {
					return
				}
				event.stopMapPropagation = true

				marker.remove()
				clearHighlights(map)
				clearSelections()
				selectedTransaction.value = null
			})

			// Highlight parcels when a transaction is previewed
			watch(previewedTransaction, (curr, prev) => {
				if (config.ui.has(Feature.TransactionSourceAPI)) {
					if (prev) {
						if (selectedTransaction.value && prev.id === selectedTransaction.value.id) {
							return
						}
						unhighlightParcels(map, ...prev.location.parcelNumbers)
						map.getCanvas().style.cursor = ""
					}
					if (curr) {
						highlightParcels(map, ...curr.location.parcelNumbers)
						map.getCanvas().style.cursor = "pointer"
					}
				} else if (config.ui.has(Feature.TransactionSourceTiles)) {
					if (prev) {
						setTransactionFeatureState(map, prev, { preview: false })
						map.getCanvas().style.cursor = ""
					}
					if (curr) {
						setTransactionFeatureState(map, curr, { preview: true })
						map.getCanvas().style.cursor = "pointer"
					}
				}
			})

			watch(selectedTransaction, async (curr, prev) => {
				if (prev) {
					setTransactionFeatureState(map, prev.presentationId, { highlight: false })
				}
				if (curr) {
					const { longitude, latitude } = curr.location.coordinates
					if (!preventFlyTo) {
						await map.flyTo({
							center: new mapboxgl.LngLat(longitude, latitude),
							zoom: 15,
							speed: 1.6,
						})

						map.once("moveend", () => {
							setTransactionFeatureState(map, curr.presentationId, { highlight: true })
						})
					} else {
						setTransactionFeatureState(map, curr.presentationId, { highlight: true })
					}
				}
				preventFlyTo = false
			})

			watch(currentStyle, name => {
				map.setStyle(STYLE_IMPORTS[name])
				selectedFeatureId.value = null
			})

			watch(
				prospects,
				() => {
					refreshSource(map, "prospects-source")
				},
				{ deep: true }
			)

			// prettier-ignore
			watch(hoveredListingId, (newVal, oldVal) => {
				if (oldVal) {
					map.setFeatureState({ source: "listings-source",  id: oldVal }, { highlight: false })
					map.setFeatureState({ source: "offmarket-source", id: oldVal }, { highlight: false })
				}
				if (newVal) {
					map.setFeatureState({ source: "listings-source",  id: newVal }, { highlight: true })
					map.setFeatureState({ source: "offmarket-source", id: newVal }, { highlight: true })
				}
				if (selectedListingId.value) {
					map.setFeatureState({ source: "listings-source",  id: selectedListingId.value }, { highlight: true })
					map.setFeatureState({ source: "offmarket-source", id: selectedListingId.value }, { highlight: true })
				}
			})

			// Set map padding, which alters what is considered center for
			// certain functions, like flyTo and fitBounds.
			// see https://docs.mapbox.com/mapbox-gl-js/api/properties/#paddingoptions
			watch(
				isLargeScreen,
				isLarge => {
					if (isLarge) {
						map.setPadding({
							left: 468,
							right: 1019,
						})
					} else {
						map.setPadding({
							top: 0,
							bottom: 0,
							left: 0,
							right: 0,
						})
					}
				},
				{ immediate: true }
			)

			watch(selectedListingId, (newSelectedId, oldSelectedId) => {
				if (newSelectedId !== null) {
					const coords = map
						.getStyle()
						.sources["listings-source"].data.features.find(feature => feature.properties["id"] === newSelectedId)
						.geometry.coordinates

					map.flyTo({
						center: new mapboxgl.LngLat(coords[0], coords[1]),
						zoom: 15,
						speed: 1.6,
					})
				}

				// TODO what does this do in practice? what gets highlighted exactly?
				// TODO the conditional logic doesn't seem right; double check
				if (newSelectedId !== null) {
					map.setFeatureState({ source: "listings-source", id: oldSelectedId }, { highlight: false })
					map.setFeatureState({ source: "offmarket-source", id: oldSelectedId }, { highlight: false })
				}
				if (oldSelectedId !== null) {
					map.setFeatureState({ source: "listings-source", id: newSelectedId }, { highlight: true })
					map.setFeatureState({ source: "offmarket-source", id: newSelectedId }, { highlight: true })
				}
			})

			// NOTE:
			// Ideally codependent source layers would be included in the same
			// tile source, as two items in the "tiles" array
			// (`map.addSource("name", {tiles: [...]})`). Unfortunately this
			// produces a mapbox-gl glitch where the layer filling is
			// inconsistant and changes based on zoom level. Bumbing mapbox-gl
			// to the latest version (3.5.1) did not help.
			//
			// TODO:
			// Combine codependent source layers in a single db view so that
			// they become a single data source. This would eleminate the below
			// (error prone) logic and possibly improve performance.
			watch(visibleLayers, (newVal, oldVal) => {
				const layersToShow = newVal.filter(g => !oldVal.includes(g))
				const layersToHide = oldVal.filter(g => !newVal.includes(g))

				layersToShow.forEach(layerId => {
					map.setLayoutProperty(layerId, "visibility", "visible")

					// when a layer is toggled, so should its codependencies
					switch (layerId) {
						case "administrative-divisions/regions": {
							map.setLayoutProperty("administrative-divisions/region_labels", "visibility", "visible")
							break
						}
						case "administrative-divisions/rcms": {
							map.setLayoutProperty("administrative-divisions/rcm_labels", "visibility", "visible")
							break
						}
						case "administrative-divisions/municipalities": {
							map.setLayoutProperty("administrative-divisions/municipality_labels", "visibility", "visible")
							break
						}
						case "administrative-divisions/postal-codes": {
							map.setLayoutProperty("administrative-divisions/postal-code_labels", "visibility", "visible")
							break
						}
					}
				})
				layersToHide.forEach(layerId => {
					map.setLayoutProperty(layerId, "visibility", "none")

					// when a layer is toggled, so should its codependencies
					switch (layerId) {
						case "administrative-divisions/regions": {
							map.setLayoutProperty("administrative-divisions/region_labels", "visibility", "none")
							break
						}
						case "administrative-divisions/rcms": {
							map.setLayoutProperty("administrative-divisions/rcm_labels", "visibility", "none")
							break
						}
						case "administrative-divisions/municipalities": {
							map.setLayoutProperty("administrative-divisions/municipality_labels", "visibility", "none")
							break
						}
						case "administrative-divisions/postal-codes": {
							map.setLayoutProperty("administrative-divisions/postal-code_labels", "visibility", "none")
							break
						}
					}
				})
			})
		})

		return {
			mapCenter,
			selectedFeatureId,
			handleSearchByAddress,
			handleSearchByParcel,
			handleSearchByTransaction,
		}
	},
}
</script>

<style scoped>
article {
	height: 100%;
	display: flex;

	#map {
		flex: 1 1 auto;
	}
}
</style>
