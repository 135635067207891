<template>
	<div class="listings-filters">
		<section>
			<h5>{{ $t("filters.dev-type-title") }}</h5>
			<div class="content">
				<toggle-list v-model="activeLayers" :items="layersItems" :is-icon-active="true" />
			</div>
			<div>
				<div v-if="store.currentUser?.has(Permission.TransactionWrite)" class="content filters">
					<vue-select
						autocomplete="off"
						v-model="transactionTypesSelected"
						multiple
						:options="transactionTypes"
						:placeholder="$t('filters.transaction-types')"
					>
					</vue-select>
				</div>
				<div v-if="store.currentUser?.has(Permission.TransactionWrite)" class="content filters">
					<vue-select
						autocomplete="off"
						v-model="transactionStatusesSelected"
						multiple
						:options="transactionStatuses"
						:placeholder="$t('filters.transaction-status')"
					>
					</vue-select>
				</div>
				<div v-if="store.currentUser?.has(Permission.TransactionWrite)" class="content filters">
					<vue-select
						autocomplete="off"
						v-model="transactionCategoriesSelected"
						multiple
						:options="transactionCategories"
						:placeholder="$t('filters.transaction-category')"
					>
					</vue-select>
				</div>
				<div v-if="store.currentUser?.has(Permission.TransactionWrite)" class="content filters">
					<vue-select
						autocomplete="off"
						v-model="transactionLegacyStateSelected"
						:options="transactionLegacyStates"
						:placeholder="$t('filters.transaction-legacy')"
					></vue-select>
				</div>
			</div>
		</section>

		<section>
			<h5>{{ $t("filters.others-title") }}</h5>
			<div class="content range-filters">
				<div class="range superficy">
					<div class="title">{{ $t("listings.superficy") }}</div>
					<div class="values">
						<div class="lower bound">
							<label>{{ $t("listings.label_min") }}</label>
							<input
								type="number"
								min="0"
								:value="rangeFilters.superficy.min"
								:step="rangeFilters.superficy.step"
								@input="event => handleFilterUpdate('superficy', 'min', Number(event.target.value))"
							/>
						</div>
						<div class="upper bound">
							<label>{{ $t("listings.label_max") }}</label>
							<input
								type="number"
								min="0"
								:value="rangeFilters.superficy.max"
								:step="rangeFilters.superficy.step"
								@input="event => handleFilterUpdate('superficy', 'max', Number(event.target.value))"
							/>
						</div>
					</div>
				</div>

				<div class="range constructible">
					<div class="title">{{ $t("listings.constructible") }}</div>
					<div class="values">
						<div class="lower bound">
							<label>{{ $t("listings.label_min") }}</label>
							<input
								type="number"
								min="0"
								:value="rangeFilters.constructible.min"
								:step="rangeFilters.constructible.step"
								@input="event => handleFilterUpdate('constructible', 'min', Number(event.target.value))"
							/>
						</div>
						<div class="upper bound">
							<label>{{ $t("listings.label_max") }}</label>
							<input
								type="number"
								min="0"
								:value="rangeFilters.constructible.max"
								:step="rangeFilters.constructible.step"
								@input="event => handleFilterUpdate('constructible', 'max', Number(event.target.value))"
							/>
						</div>
					</div>
				</div>

				<div class="range price">
					<div class="title">{{ $t("listings.price") }}</div>
					<div class="values">
						<div class="lower bound">
							<label>{{ $t("listings.label_min") }}</label>
							<input
								type="number"
								min="0"
								:value="rangeFilters.price.min"
								:step="rangeFilters.price.step"
								@input="event => handleFilterUpdate('price', 'min', Number(event.target.value))"
							/>
						</div>
						<div class="upper bound">
							<label>{{ $t("listings.label_max") }}</label>
							<input
								type="number"
								min="0"
								:value="rangeFilters.price.max"
								:step="rangeFilters.price.step"
								@input="event => handleFilterUpdate('price', 'max', Number(event.target.value))"
							/>
						</div>
					</div>
				</div>

				<div v-if="isUserLoggedIn" class="range date">
					<div class="title">{{ $t("filters.data-range") }}</div>
					<div class="values">
						<div class="lower bound">
							<label>{{ $t("listings.label_min") }}</label>
							<input
								type="date"
								value="2024-01-01"
								@input="event => handleFilterUpdate('date', 'min', extendedISO8601ToDate(event.target.value))"
							/>
						</div>
						<div class="upper bound">
							<label>{{ $t("listings.label_max") }}</label>
							<input
								type="date"
								:value="new Date().toISOString().split('T')[0]"
								@input="event => handleFilterUpdate('date', 'max', extendedISO8601ToDate(event.target.value))"
							/>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="location-and-type">
			<h5>{{ $t("listings.locationAndType") }}</h5>

			<div>
				<div>
					<div class="label">{{ $t("listings.typeDev") }}</div>

					<div class="content filters">
						<vue-select
							v-model="devTypeSelected"
							multiple
							:options="devTypeItems"
							:placeholder="$t('listings.all-dev-types')"
						>
						</vue-select>
					</div>
				</div>

				<div v-if="isUserLoggedIn && config.ui.has(Feature.Transactions)">
					<div class="label">{{ $t("filters.municipalities-title") }}</div>

					<div class="content filters">
						<vue-select
							v-model="municipalitiesSelected"
							multiple
							:options="municipalityItems"
							:filterBy="customNormalizingFilter"
							:placeholder="$t('listings.all-cities')"
						>
						</vue-select>
					</div>

					<div class="label">{{ $t("filters.boroughs-title") }}</div>

					<div class="content filters">
						<vue-select
							v-model="boroughsSelected"
							multiple
							:options="boroughItems"
							:filterBy="customNormalizingFilter"
							:placeholder="$t('listings.all-boroughs')"
						>
						</vue-select>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script setup>
import { config, Feature } from "@/AppConfig.ts"
import ToggleList from "@/components/menu-left/filtering/ToggleList.vue"
import { UseRootStore, Permission } from "@/model/RootStore"
import { Borough, TransactionType, TransactionMetadataStatus, TransactionCategory } from "@/model/DataModel.ts"
import { getMunicipalities } from "@/utils/ApiClient"
import { storeToRefs } from "pinia"
import { computed, reactive, ref, watch, onMounted, toRaw } from "vue"
import { useI18n } from "vue-i18n"
import VueSelect from "vue-select"
import "vue-select/dist/vue-select.css"

const All = "all"
const Yes = "yes"
const No = "no"

const { developmentType: devtypeConfig } = config.filtersDefault
const { t } = useI18n()
const store = UseRootStore()
const { filters: storeFilters, isUserLoggedIn } = storeToRefs(store)
const rangeFilters = computed(() => storeFilters.value.rangeFilters)

// format a date in YYYY-MM-DD format
function dateToExtendedISO8601(date) {
	const y = date.getFullYear()
	const m = String(date.getMonth() + 1).padStart(2, "0")
	const d = String(date.getDate()).padStart(2, "0")
	return `${y}-${m}-${d}`
}
// convert a YYYY-MM-DD string to a Date
function extendedISO8601ToDate(date) {
	const [y, m, d] = date.split("-").map(Number)
	return new Date(y, m - 1, d)
}

// TODO extract and use in TransactionCardDetailed
// prettier-ignore
function tristateFromWord(word) {
	switch (word) {
		case Yes: { return true  }
		case No:  { return false }
		default:  { return null  }
	}
}

let listingTypeConfig = config.filters
if (isUserLoggedIn.value && config.ui.has(Feature.Transactions)) {
	listingTypeConfig = listingTypeConfig.concat(config.restrictedFilters)
}

const layersItems = computed(() =>
	listingTypeConfig.map(id => ({
		id,
		label: t(`listings-layers.${id}`),
	}))
)
const devTypeItems = computed(() =>
	devtypeConfig.map(id => ({
		id,
		label: t(`dev-types.${id}`),
	}))
)

const activeLayers = ref(listingTypeConfig)
const devTypeSelected = ref([])

const municipalities = ref([])
const boroughs = ref([])

const transactionTypes = computed(() => {
	return Object.values(TransactionType)
		.concat(null)
		.map(type => ({
			id: type,
			label: type,
		}))
})

const municipalitiesSelected = ref([])
const boroughsSelected = ref([])
const transactionTypesSelected = ref([])
const transactionStatusesSelected = ref([])
const transactionCategoriesSelected = ref([])
const transactionLegacyStateSelected = ref(null)

onMounted(async () => {
	if (isUserLoggedIn.value && config.ui.has(Feature.Transactions)) {
		const fetchedMunicipalities = await getMunicipalities()

		// only store unique municipality names in the ref
		municipalities.value = [...new Set(fetchedMunicipalities.map(m => m.name))]
		boroughs.value = Object.values(Borough).sort()
	}
})

// Normalize a label by removing accents and lowercasing it
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize
function normalizeLabel(name) {
	return name
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.toLowerCase()
}

const municipalityItems = computed(() =>
	municipalities.value.map(name => ({
		id: name,
		label: name,
		normalizedLabel: normalizeLabel(name),
	}))
)

const boroughItems = computed(() =>
	boroughs.value.map(name => ({
		id: name,
		label: name,
		normalizedLabel: normalizeLabel(name),
	}))
)

const transactionStatuses = computed(() => {
	return Object.values(TransactionMetadataStatus).map(status => ({
		id: status,
		label: status,
	}))
})

const transactionCategories = computed(() => {
	return Object.values(TransactionCategory).map(category => ({
		id: category,
		label: category,
	}))
})

const transactionLegacyStates = computed(() => {
	return Object.freeze([
		{ id: Yes, label: "Only" },
		{ id: No, label: "Exclude" },
		{ id: All, label: "" },
	])
})

function handleFilterUpdate(name, key, value) {
	rangeFilters.value[name][key] = value
}

// Custom filter function to handle accent-insensitive matching
function customNormalizingFilter(option, label, search) {
	if (!search) return true
	return option.normalizedLabel.includes(normalizeLabel(search))
}

watch(activeLayers, layers => {
	storeFilters.value.layers = layers
})

watch(devTypeSelected, devType => {
	storeFilters.value.devTypes = devType.length ? devType.map(d => d.id) : devtypeConfig
})

watch(municipalitiesSelected, municipalityItems => {
	storeFilters.value.municipalities = toRaw(municipalityItems).map(item => item.id)
})

watch(boroughsSelected, boroughItems => {
	storeFilters.value.boroughs = toRaw(boroughItems).map(item => item.id)
})

watch(transactionTypesSelected, types => {
	storeFilters.value.transactionTypes = toRaw(types).map(item => item.id)
})

watch(transactionStatusesSelected, statuses => {
	storeFilters.value.transactionStatuses = toRaw(statuses).map(item => item.id)
})

watch(transactionCategoriesSelected, categories => {
	storeFilters.value.transactionCategories = toRaw(categories).map(item => item.id)
})

watch(transactionLegacyStateSelected, legacy => {
	storeFilters.value.transactionLegacyState = legacy && tristateFromWord(toRaw(legacy).id)
})
</script>

<style scoped>
.listings-filters {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: 20px;
	overflow-y: auto;
	overflow-x: hidden;
	padding-top: 22px;

	section {
		display: flex;
		flex-direction: column;
		gap: 15px;
		h5 {
			font-family: "basier_circlesemibold", sans-serif;
			font-size: 14px;
			font-weight: 600;
			line-height: 18px;
		}
		&:not(:last-child) {
			border-bottom: 1.5px solid #c4c4c4;
			padding-bottom: 5px;
		}
	}
	.content {
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding-left: 12px;
		padding-right: 4px;
	}
	.range-filters {
		padding: 0px;
		padding-bottom: 20px;

		.range {
			--input-width: 125px;

			.title {
				font-family: "basier_circleregular", sans-serif;
				font-size: 14px;
				font-weight: 400;
				padding-bottom: 8px;
			}
			.values {
				display: flex;
				flex-direction: row;
				justify-content: space-around;
				margin-top: 1em;

				.bound {
					width: var(--input-width);

					&.lower {
						text-transform: capitalize;
					}
				}

				label {
					font-size: 1.2rem;
					padding-bottom: 8px;
					font-style: italic;
					opacity: 0.75;

					&::after {
						content: ": ";
					}
				}
				input {
					border: 1px solid #ddd;
					width: var(--input-width);

					&[type="number"] {
						padding: 4px 0 4px 8px;
					}
					&[type="date"] {
						display: flex;
						justify-content: flex-end;
						padding: 4px 8px;
					}
					&:focus {
						border: 2px solid #0074d9;
						outline: none;
					}
				}
			}
		}
	}

	.location-and-type {
		.label {
			font-family: "basier_circleregular", sans-serif;
			font-size: 14px;
			font-weight: 400;
			padding-bottom: 8px;
		}
	}

	.filters {
		min-height: 50px;
		font-size: 1.5rem;
		width: 32rem;
		position: relative;

		ul,
		li {
			position: relative;
		}
	}
}
</style>
